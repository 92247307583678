.dashboard_wrapper {
  // box-shadow: 0 5px 15px #ccc;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}
.charts_wrapper {
  display: flex;
  width: 100%;
  gap: 30px;
  margin-bottom: 24px;
}
.actions_heading_box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.charts_heading {
  font-size: 18px;
  color: #44546a;
}
.myactions_heading_box,
.meeting_heading_box {
  color: #44546a;
  flex: 1;
  box-shadow: 0 5px 15px #ccc;
  padding: 1rem 2rem;
  .heading_body {
    &:not(:last-child) {
      border-bottom: 1px solid #b8b5b547;
      margin-bottom: 12px;
    }
  }
}
.meeting_heading_box {
  .heading_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    .actions_box {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
.myactions_heading_box {
  .card_list_wrapper {
    padding: 0px;
    margin: 0px;
    .card_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
.charts_outer_box {
  box-shadow: 0 5px 15px #ccc;
  padding: 0.5rem 1rem;
  margin-bottom: 16px;
}
.see_more {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
.chart_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  p {
    margin: 0px;
    color: #44546a;
  }
  .indicators_container {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    .color_indicator_wrapper {
      display: flex;
      align-items: center;
      gap: 4px;

      .color_indicator {
        display: flex;
        width: 20px;
        height: 20px;

        border-radius: 50%;
      }
      .color_indicator_complete {
        background-color: #f49782;
      }
      .color_indicator_incomplete {
        background-color: #fbdcd5;
      }
    }
  }
}
.heading_body {
  .card_body {
    word-break: break-word;
    .btn_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card_heading {
        font-size: 16px;
        margin: 0px;
        font-weight: 500;
        margin-bottom: 4px;
        padding-left: 0.3rem;
        color: gray;
      }
      .blocker_title {
        border-left: rgb(237, 82, 46) 3px solid;
      }
      .decision_title {
        border-left: rgb(9, 38, 213) 3px solid;
      }
      .update_title {
        border-left: rgb(127, 131, 155) 3px solid;
      }
      .admin_topic_title {
        border-left: #9933ff 3px solid;
      }
    }
    .objectiveNamebox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .actions_box {
        display: flex;
        gap: 8px;
      }
    }
    .btn-common {
      white-space: nowrap;
    }
  }
}
.comment_box {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0px;
  margin: 4px 0px 8px;
  .comments_count {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b8b5b547;
    padding: 5px;
    min-width: 30px;
    border-radius: 4px;
    // color: white;
    // font-weight: 700;
  }
}
