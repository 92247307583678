.setup-wizard-component .dual-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0 0;
  padding-bottom: 6rem;
}

.setup-wizard-component .dual-button-container .dual-btn {
  padding: 0.75rem 0;
  margin: 0 1rem;
  width: 10vw;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.setup-wizard-component .dual-button-container button {
  background-color: rgb(237, 82, 46);
  height: 3rem;
}

.setup-wizard-component .dual-button-container button:hover {
  background-color: rgb(246, 145, 60);
}

.setup-wizard-component .dual-btn .nav-arrow {
  width: 1rem;
  height: 1rem;
  position: absolute;
}

.setup-wizard-component .next {
  right: 1.5rem;
}

.setup-wizard-component .previous {
  left: 1.5rem;
}

.setup-wizard-component .previous {
  transform: scaleX(-1);
}

.setup-wizard-component .dual-button-container .disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

.setup-wizard-component .dual-button-container .disabled:hover {
  background-color: rgb(237, 82, 46);
}

/* TEMPORARY */

.setup-wizard-component .dual-button-container .dual-next-btn span {
  position: relative;
  right: 0.25rem;
}

html body .setup-wizard-component .start-button {
  height: 3rem;
  background-color: rgb(237, 82, 46);
  padding: 1.5rem 0;
  width: 15vw;
  border-radius: 5rem;
  font-size: 18px;
}

.setup-wizard-component .dual-button-container .disabled-next-btn,
.setup-wizard-component .dual-button-container .disabled-submit-btn {
  cursor: auto;
  position: relative;
  background-color: rgba(237, 82, 46, 0.6);
}

.setup-wizard-component .dual-button-container .disabled-submit-btn:hover,
.setup-wizard-component .dual-button-container .disabled-next-btn:hover {
  background-color: rgba(237, 82, 46, 0.6);
}

.setup-wizard-component .dual-btn-warning-message {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 7vh;
  color: rgb(255, 0, 0);
}

.setup-wizard-component .dual-button-container .disabled-next-btn:hover .dual-btn-warning-message {
  display: flex;
}
