.add-comment-action {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
}

.add-btn-comment {
  padding: 0.3rem 2rem;
  white-space: nowrap;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: rgb(237, 82, 46);
  color: #fff;
}

.point-event {
  pointer-events: none;
}

.error {
  &.error-add-commnet {
    font-size: 12px;
    line-height: 16px;
    color: red;
    margin-top: 4px;
  }
}

.form-field.action-field {
  &.create-template-field {
    .css-13cymwt-control {
      border-radius: 10px;
    }
  }
  &.create-template-select-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    .add-btn-comment {
      font-weight: normal;
      min-height: 38px;
      border-radius: 0 10px 10px 0;
      padding: 0.3rem 0.7rem;
    }
  }
  &.create-template-input-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    .add-btn-comment {
      font-weight: normal;
      min-height: 38px;
      border-radius: 0 10px 10px 0;
      padding: 0.3rem 0.7rem;
    }
    .custom-input {
      border-radius: 10px 0 0 10px;
      width: 100%;
      height: 22px;
      &:focus {
        height: 20px;
      }
    }
  }
}

.custom-create-template-modal {
  .modal-overlay {
    .modal-container {
      .modal-container-padding {
        width: 80%;
      }
    }
  }
}

.create-template-option {
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  .custom-input {
    width: auto;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.form-field.form-field {
  &.create-template-checkbox {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    label {
      font-size: 16px;
      margin-bottom: 0;
      font-weight: normal;
    }
    .custom-input {
      width: 20px;
      margin: 0;
    }
  }
}
