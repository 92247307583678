.capacity-step .goal-cell {
  width: 20vw;
}

.capacity-step th,
.capacity-step td {
  width: 15vw;
}

.capacity-step .capacity-cell input {
  width: 5vw;
}

.capacity-step h2 {
  margin: 2rem 0 3rem 0;
}

.capacity-step .credits-left-text {
  margin-bottom: 2rem;
  font-size: 18px;
}

.capacity-step .credits-left {
  font-size: 20px;
  color: rgb(9, 38, 213);
}

.capacity-step .warning-text {
  color: rgb(255, 0, 0);
}

.capacity-step select {
  max-width: none;
}

.capacity-step select {
  width: 80%;
}
