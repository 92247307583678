.kpi-overdue-table {
  display: grid;
  grid-template-columns: 0.1fr 1fr 1fr 0.5fr 0.8fr 0.8fr 0.8fr 0.5fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
  position: relative;
  text-align: left;
  overflow-wrap: break-word;
}

.kpi-overdue-table-header {
  font-weight: bold;
  padding-left: 0.5rem;
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: keep-all;
}
