.objectives-step .goal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.objectives-step .objective-instructions {
  width: 45vw;
  margin: 1rem 0;
}

.objectives-step .instruction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.objectives-step .instruction img {
  width: 35px;
  height: 35px;
  margin-right: 1rem;
}

.objectives-step h2 {
  margin: 2rem 0 3rem 0;
}

.objectives-step ul {
  margin-bottom: 2rem;
  list-style: none;
}

.objectives-step .goal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 4rem 2rem 4rem;
  border-radius: 5px;
  min-width: 35vw;
}

.objectives-step .goal-input {
  margin-bottom: 1rem;
  min-width: 30vw;
  border-radius: 5px;
  border: 1px solid rgb(238, 238, 238);
  padding: 0 1rem;
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.objectives-step .invalid {
  border: rgb(255, 0, 0) 1px solid;
}

.objectives-step .edit-btn,
.objectives-step .delete-btn {
  width: 22px;
  height: 22px;
  margin-left: 1rem;
  cursor: pointer;
  position: relative;
  bottom: 0.5rem;
}

.objectives-step .add-btn {
  margin-top: 1rem;
}

.objectives-step .input-wrapper {
  position: relative;
}

.objectives-step .warning {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 12.5px;
  left: -2rem;
}

.objectives-step .no-objectives-text {
  font-size: 18px;
  margin-bottom: 2rem;
}
