.task-inner-container {
  .task-title {
    margin: 0 0 30px 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
}

.action-box {
  height: 1.5rem;
  width: fit-content;
  padding: 0 0.5rem 0 0.5rem;
  display: flex;
  color: white;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
}

.action-box-new-format {
  height: 1rem;
  width: fit-content;
  padding: 0 0.5rem 0 0.5rem;
  display: flex;
  color: white;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
}

.task-action-conatiner {
  display: flex;
  // align-items: center;
  justify-content: flex-end;
  span {
    font-size: 14px;
    color: rgb(9, 38, 213);
    line-height: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    // &:first-child {
    //   padding-right: 8px;
    //   border-right: 1px solid black;
    //   margin-right: 8px;
    // }
  }
}

.tast-create-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 14px;
    color: grey;
    line-height: 18px;
  }
}
