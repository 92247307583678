.my-reminders-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 0.8rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 100%;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 8px;
  /* word-wrap: break-word; */
  word-break: break-word;
}

.my-follow-up-inner-grid {
  display: grid;
  grid-template-columns: 4fr 3fr 5fr 8fr 3fr 3fr 2fr;
  gap: 1rem;
}

.my-reminders-meeting-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  height: 2.5rem;
  padding-top: 0.5rem;
}

.my-reminders-meeting-content {
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  height: 2.5rem;
  padding-top: 0.5rem;
}
