.notification-type {
  color: gray;
  margin-left: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 98.5%;
  height: 1.5em;
  white-space: nowrap;
  position: relative;
}

.notification-type.ellipsis-with-quote::after {
  content: '"';
  position: absolute;
  right: 0;
  bottom: 0;
}

.notification-metadata {
  color: gray;
  font-style: italic;
}

// .notification-text{
//
// }

.notification-link {
  cursor: pointer;
  color: #4472c4;
}
