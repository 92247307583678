.file-upload-modal-title {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 25px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  color: rgb(94, 134, 204);
  width: 100%;
}

.file-upload-modal {
  .modal-container {
    text-align: left;

    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
}
