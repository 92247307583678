.my-notebook {
  padding-top: 3.5rem;
}

.my-notebook h2 {
  width: auto;
  text-align: center;
  margin: 2rem auto;
}

.my-notebook .my-notebook-view-body {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  gap: 1rem;
}

.my-notebook .my-notebook-nav-container {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.my-notebook-nav-container .nav-option {
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  border: 1px solid rgb(238, 238, 238);
  cursor: pointer;
}

.my-notebook-nav-container .active {
  border-bottom: 2px solid rgb(9, 38, 213);
}

.my-notebook-container {
  width: 100%;
  display: flex;
}

.my-notebook-notes-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.my-notebook-notes-left-subcontainer {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgb(238, 238, 238);
  //border-right: 1px solid rgb(238, 238, 238);
  justify-content: space-between;
  width: 20rem;
}

.my-notebook-notes-left-subcontainer-divider {
  width: 100%;
  //border-radius: 1px;
  height: 0.5px;
  background-color: rgb(238, 238, 238);
}

.my-notebook-notes-right-subcontainer {
  width: 45rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  //border-top:  1px solid rgb(238, 238, 238);
  //border-right: 1px solid rgb(238, 238, 238);
  //border-bottom: 1px solid rgb(238, 238, 238);
  padding: 0rem 1rem 0 1rem;
}

.my_notebook_notes_dual_button_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.my-notebook-notes-left-subcontainer-note-title {
  font-weight: bold;
}

.my_notebook_notes_dual_button_container-inner {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
}

.my-notebook-notes-left-subcontainer-left {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  width: 13rem;
}

.my-notebook-notes-left-subcontainer-note {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.selected-note {
  background-color: rgb(238, 238, 238);
}

.my-notebook-notes-left-subcontainer-note-date {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: darkgray;
  // width:4rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.note-box {
  height: 72vh;
  overflow-x: hidden;
  overflow-y: scroll;
  border-top: 1px solid rgb(238, 238, 238);
  border-right: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid rgb(238, 238, 238);
}

.note-box::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

.note-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background: #e6e5e5;
}
