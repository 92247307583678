.allocation-table {
  display: grid;
  grid-template-columns: 40px 1.5fr 0.5fr;
  grid-gap: 1rem;
  grid-row-gap: 3rem;
  margin-top: 1rem;
}

.allocation-table-header {
  font-weight: bold;
  padding-left: 1rem;
}

.allocation-display {
  font-size: 20px;
  width: 5rem;
  text-align: left;
}
