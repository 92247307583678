.changes-report-container {
  width: 97%;
  margin-top: 1rem;
}

.changes-report-container-navbar-open {
  width: 86%;
}

.changes-report {
  padding-top: 5rem;
}

.changes-report h2 {
  width: auto;
  text-align: center;
  margin: 2rem auto;
}

.changes-report .changes-report-body {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
}

.changes-report .meetingview-nav-container {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.changes-report-actions_table {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr 0.8fr 0.7fr 0.2fr;
  row-gap: 1rem;
  column-gap: 1rem;
  padding: 0 1rem;
  width: 75vw;
}

.changes-report-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: normal;
  //word-break: break-word;
  height: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.changes-report-meeting-topic-table {
  display: grid;
  grid-template-columns: 2fr;
  gap: 0.1rem 0.4rem;
  width: 75vw;
}

.changes-report-meeting-topic-inner-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 0.7fr 0.7fr 0.7fr 0.5fr 0.3fr;
  column-gap: 1rem;
  row-gap: 0.3rem;
}
