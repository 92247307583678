.governance-view {
  margin: 0 auto;
}

.governance-view .reveal-governance-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.governance-view .reveal-governance-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  border-radius: 10px;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.governance-view .governance-details-div {
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.governance-view .collapse-arrow-div {
  width: 5vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.governance-view .collapse-arrow {
  width: 3vw;
  height: 3vw;
  transition: all 250ms;
}

.governance-view .governance-tab-title {
  width: 35vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.governance-view .reveal-governance-btn-container .opened-arrow {
  transform: rotate(180deg);
}

.governance-view .big-btn {
  background-color: rgb(237, 82, 46);
  margin-top: 2rem;
  font-size: 20px;
  padding: 0.5rem 2rem;
  border-radius: 5px;
}

.governance-view table th {
  font-size: 18px;
  width: 8vw;
}

.governance-view table td {
  font-size: 16px;
  width: 8vw;
}

.bad-text {
  font-weight: bold;
  color: rgb(255, 0, 0);
}

.good-text {
  font-weight: bold;
  color: rgb(0, 138, 0);
}

.neutral-text {
  color: rgb(138, 138, 138);
}

.view_all {
  color: blue;
  cursor: pointer;
  margin-top: 16px;
}

.shadow-governance-box {
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
}

.governance-table-title {
  padding-left: 0.5rem;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.governance-table-cell {
  padding: 0 0.5rem 0 0.5rem;
  word-break: break-word;
  font-size: 14px;
  font-weight: normal;
}
