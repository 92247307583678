.mei-modal-section-col {
  display: flex;
  flex-direction: column;
}

.mei-modal-section-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mei-modal-section-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #5780ca;
  text-align: left;
}

.mei-modal-highlight-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #5780ca;
  display: flex;
}

.mei-modal-breakdown-category {
  font-size: 14px;
  color: #77acdd;
  font-weight: bold;
}

.mei-modal-common-text {
  font-size: 16px;
}

.mei-modal-green-mei {
  color: white;
  background-color: green;
  border: 1px solid green;
  border-radius: 6px;
  font-size: 18px;
  font-weight: normal;
}

.mei-modal-grey-mei {
  color: white;
  background-color: grey;
  border: 1px solid grey;
  border-radius: 6px;
  font-size: 18px;
  font-weight: normal;
}

.mei-modal-red-mei {
  color: white;
  background-color: red;
  border: 1px solid red;
  border-radius: 6px;
  font-size: 18px;
  font-weight: normal;
}

.mei-link {
  color: #0926d5;
  text-decoration: underline;
  cursor: pointer;
}
