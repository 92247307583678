.audio-recorder {
  background-color: #ebebeb;
  box-shadow: 0 2px 5px #bebebe;
  border-radius: 20px;
  box-sizing: border-box;
  width: 40px;
  display: flex;
  align-items: center;
  padding: 12px;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.audio-recorder-mic {
  cursor: pointer;
  height: 16px;
  color: #000;
}

.audio-recorder-timer-v2 {
  font-family:
    Segoe UI,
    Tahoma,
    Geneva,
    Verdana,
    sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
}

.audio-recorder-status {
  margin-left: 15px;
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  animation-name: fading-ar-status;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  color: #d00;
}

.audio-recorder-status-dot {
  background-color: #d00;
  border-radius: 50%;
  height: 10px;
  width: 9px;
  margin-right: 5px;
}

.audio-recorder-options {
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.recording {
  border-radius: 12px;
  width: 30vw;
  transition: all 0.2s ease-out;
}

.display-none {
  display: none;
}

@keyframes fading-ar-status {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
