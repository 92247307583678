li.diamond-yellow {
  margin: 1rem;
  padding-left: 1em;
  text-align: start;
}

li.diamond-yellow::marker {
  color: rgb(246, 145, 60);
  content: '♦';
}

ul.diamond-yellow-list ul li::marker {
  content: '♢';
}
