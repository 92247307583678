.member-icon-wrapper {
  display: flex;
  justify-content: space-between;
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 25px;
  height: 25px;
  background-color: rgb(237, 82, 46);
  border-radius: 100%;
  cursor: pointer;
  padding: 0;

  img {
    width: 18px;
    height: 18px;
    object-fit: cover;
  }
}

.objective-list-view {
  padding: 24px;
}

.team-objectives-head-list {
  display: grid;
  grid-template-columns: 50% 50%;
  list-style: none;
  gap: 0px;
  margin: 0;
  padding: 0;

  .head-item {
    padding: 10px 0;
    text-align: start;
    font-weight: 700;
    font-size: 18px;
  }

  .body-item-objective {
    font-size: 16px;
    font-weight: 300;
    color: rgb(0, 0, 0);
  }

  &:first-child {
    margin-bottom: 20px;
  }

  &.body-objective-list {
    gap: 15px 25px;
  }
}

.inner-member-list {
  list-style: none;
  margin: 0;
  padding: 0;

  .body-item {
    padding: 5px 0;
    list-style: none;
  }
}
