.employee-boxes-al-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  width: 90%;
  margin: 0 auto;
}

.employee-boxes-al-scrollable {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 10px;
  width: 90%;
  margin: 0 auto;
  overflow-x: auto;
}

.employee-boxes-cxo {
  // display: flex;
  // flex-direction: column;
  // overflow-x: auto;
  // width: 90%;
  // margin: 0 auto;
}
