.list-members {
  margin: 0;
  padding: 0;

  .single-member {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    .member-text {
      font-weight: 700;
    }
  }
}

.action-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 20px;

  .btn {
    margin: 0 1rem;
    padding: 0.5rem 0;
    width: 10vw;
    border: none;
    border-radius: 2.5px;
    cursor: pointer;
    font-size: 14px;
  }

  .btn-cancel {
    background-color: rgb(200, 200, 200);
    color: black;
  }

  .btn-save {
    background-color: rgb(237, 82, 46);
    color: #fff;

    &:hover {
      background-color: rgb(246, 145, 60);
    }
  }
}
