.obj-report-modal {
  .modal-container {
    text-align: left;
    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
  width: 100%;
  height: 100%;
}

.obj-report-modal-title {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 25px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  color: rgb(94, 134, 204);
}

.meeting-topic-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 100%;
  border-radius: 10px;
  word-break: break-word;
}

.meeting-topic-table {
  display: grid;
  grid-template-columns: 2fr;
  gap: 0.1rem 0.4rem;
}

.meeting-topic-inner-grid {
  display: grid;
  grid-template-columns: 6fr 5fr 2.2fr 2.2fr 2fr 1fr;
  column-gap: 1rem;
  row-gap: 0.3rem;
}

.my-meeting-topic-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  height: 1.5rem;
  padding-top: 0.5rem;
}

.my-meeting-topics-cell {
  text-align: left;
  margin-top: 1rem;
}

.meeting-topic-tagbox {
  height: 1.3rem;
  width: 4rem;
  min-width: 4rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  display: flex;
  color: white;
  border-radius: 6px;
  margin-right: 0.5rem;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.button {
  background-color: #a5a5a5;
  color: white;
  padding: 0.5rem 2.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: center;
  min-width: 4rem;
  margin-right: 2rem;
}

.button:hover {
  background-color: darkgray;
}
