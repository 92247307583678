.ai-workshop .score-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.ai-workshop .content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.ai-workshop .prompt-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-container-title {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 columns */
  grid-template-rows: repeat(1, 1fr); /* 3 rows */
  gap: 10px; /* Adjust the gap between grid items */
}

.grid-item-title {
  border-bottom: 1px solid #ccc;
  //border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 columns */
  grid-template-rows: repeat(3, 1fr); /* 3 rows */
  gap: 10px; /* Adjust the gap between grid items */
}

.grid-item {
  //border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
}
