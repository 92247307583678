.container {
  width: 83%;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;
  padding-left: 1rem;
}

.container-meeting-topic {
  width: 97%;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}
.expanded .container-meeting-topic {
  width: 97%;
}

.header_box {
  display: flex;
  flex-direction: column;
  align-items: start;
  // justify-content: space-between;
  margin-bottom: 0.5rem;
  .btn-common {
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    width: 10vw;
    white-space: nowrap;
  }
  .all-btn {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  .blocker-btn {
    background-color: rgb(237, 82, 46);
    color: white;
  }
  .blue-btn {
    background-color: rgb(9, 38, 213);
    color: white;
  }
  .update-btn {
    background-color: rgb(200, 200, 200);
    color: white;
  }
  .admin-topic-btn {
    background-color: #9933ff;
    color: white;
  }

  .left_side_header {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 1.5rem;
    flex-direction: column;
    width: 100%;
  }
  .left_side_container_left {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    width: 100%;
  }
  .left_side_container_right {
    width: 75%;
  }
  .right_side_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    flex-direction: row;
  }
  .right_side_header_container_left {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
    padding-top: 1px;
  }
  .right_side_header_container_right {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
    padding-top: 1px;
  }
  .right_side_header_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 1rem;
    column-gap: 2rem;
  }
  .add_meeting_topics {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 4rem;
    flex-direction: row;
    width: 50%;
    margin-right: 0.5%;
  }
}
.add_meeting_topics {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 4rem;
  flex-direction: row;
  width: 50%;
  margin-right: 0.5%;
}
.Cards_container {
  // transform: rotateX(180deg);
  // overflow-x: auto;

  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 10px;
  text-align: left;
  width: fit-content;
}

.cards_inner_container {
  transform: rotateX(180deg);
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 10px;
}

.underline-style {
  border-bottom: 2.5px solid #808080;
  margin-top: 4px;
}

@keyframes infinite-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
