.goal-column {
  width: 25vw;
}

.activites-step select {
  border-radius: 0.15rem;
  padding: 0.25rem 0.5rem;
  border: 0.1rem solid rgb(238, 238, 238);
}

.objective-header {
  display: flex;
  justify-content: 'space-between';
}

.objective-header img {
  margin: auto;
  padding-left: 0.5rem;
}

.checkbox-area {
  display: grid;
  place-items: center;
}
