.Card {
  // width: 25%;
  padding: 10px 6px;
  //box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: 2px solid rgba(128, 128, 128, 0.2392156863);
  //border-left: 2px solid rgba(128, 128, 128, 0.2392156863);
  // margin-bottom: 8px;
  // border-radius: 4px;
  background-color: white;
  width: 16rem;
  // Tkxel code check if it is necessary june 09 2023
  // width: 350px;
  // min-width: 350px;
  transition:
    300ms opacity,
    300ms transform;

  .card_header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2392156863);
    padding-bottom: 8px;
    width: 16rem;
    .top_title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      .top_line {
        height: 6px;
        flex: 1;
      }
      .blocker_title {
        color: rgb(237, 82, 46);
      }
      .top_line_blocker {
        background-color: rgb(237, 82, 46);
      }
      .decision_title {
        color: rgb(9, 38, 213);
      }
      .top_line_decision {
        background-color: rgb(9, 38, 213);
      }
      .update_title {
        color: rgb(127, 131, 155);
      }
      .top_line_update {
        background-color: rgb(127, 131, 155);
      }
      .admin_topic_title {
        color: #9933ff;
      }
      .top_line_admin_topic {
        background-color: #9933ff;
      }
    }
    .card_header_bottom_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .file-uploader-wrapper {
        position: relative;
        // width: 24px;
        height: 24px;
        overflow: hidden;
        * {
          width: 150px;
        }
        span {
          position: absolute;
          display: flex;
          justify-content: center;
          top: 0px;
          right: 0px;
          // border: 1px solid grey;
          border-radius: 4px;
          // width: 22px;
          height: 22px;
          z-index: 1;
          cursor: pointer;
        }
        input {
          opacity: 0;
          // width: 24px;
          height: 24px;
          z-index: 3;
          position: relative;
        }
      }
      .round_box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 100%;
        height: 16px;
        width: 16px;
        color: #0926d5;
        border: 2px solid #e4e7fd;
        font-weight: bold;
      }
    }
  }

  .body_text {
    min-height: 110px;
    padding: 8px 0px;
    // color: white;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2392156863);
  }
  .card_body {
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
    .body_text {
      min-height: 4rem;
      padding: 8px 0px;
      // color: white;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2392156863);
    }
    .body_bottom_box {
      background-color: rgb(242, 242, 242);
      min-height: 5rem;
      // color: white;
      padding: 8px;
      border-radius: 4px;
    }
    .body_bottom_text {
      padding: 8px;
      border-radius: 4px;
      width: 100%;
    }
  }
  .card_body_unreviewed {
    background-color: #f2f2f2;
  }
  .card_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px 0px;
    .card_footer_rightside {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
  }
}
.btn-common {
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.btn-card {
  border: none;
  width: 5rem;
  border-radius: 5px;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  //font-weight: bold;
  background-color: white;
}

.blocker-btn {
  background-color: rgb(237, 82, 46);
  color: white;
}
.blue-btn {
  background-color: rgb(9, 38, 213);
  color: white;
}

.resolve-btn {
  background-color: #f06f51;
  color: white;
}

.text-box-btn {
  background-color: #324adc;
  color: white;
}

.tonedown-blue-btn {
  color: #324adc;
  border: 1px solid #e4e7fd;
}

.tonedown-red-btn {
  color: red;
  border: 1px solid #ffeeee;
}

.text-box-grey-btn {
  background-color: gray;
  color: white;
}

.card_body_bottomBox {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  // align-items: flex-end;
  gap: 10px;
  .body_bottom_box {
    flex: 1;
  }
  .card_footer {
    flex-direction: column;
    padding: 0px;
  }
  .card_footer_rightside {
    flex-direction: column;
    align-items: stretch;
  }
  .btn-common {
    min-width: 5rem;
  }
}

.cards_popup {
  transition:
    transform 0.3s,
    -webkit-transform 0.3s;
}
.cards_popup:hover {
  -webkit-transform: translateY(-1.5rem) scale(1.03);
  transform: translateY(-1.5rem) scale(1.03);
  box-shadow: 0 5px 15px #ccc;
}

.text-box-btn-disabled {
  background-color: lightgrey;
  color: white;
}

.name-chip {
  height: 1rem;
  width: fit-content;
  padding: 0 0.2rem 0 0.2rem;
  display: flex;
  color: white;
  border-radius: 6px;
  font-size: 10px;
  margin-right: 0.2rem;
  margin-top: 0.1rem;
  font-weight: 100;
}

.mic-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  overflow: hidden;
}

.circle-container {
  position: relative;
  width: 28px; /* Increased from 24px to 28px */
  height: 28px; /* Increased from 24px to 28px */
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow:
    inset 0 0 2px #fff,
    inset 1px 0 4px #ffff00,
    inset -1px 0 4px #ff4500,
    inset 1px 0 14px #ffff00,
    /* Slightly increased from 12px */ inset -1px 0 14px #ff4500,
    /* Slightly increased from 12px */ 0 0 2px #fff,
    0 0 4px #ffff00,
    0 0 4px #ff4500,
    0 0 6px #ff6347;
  animation: spin 2s linear infinite;
}

.icon-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container {
  position: relative;
  width: 28px; /* Increased from 24px to 28px */
  height: 28px; /* Increased from 24px to 28px */
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow:
    inset 0 0 2px #fff,
    inset 1px 0 4px #ffff00,
    inset -1px 0 4px #ff4500,
    inset 1px 0 14px #ffff00,
    /* Slightly increased from 12px */ inset -1px 0 14px #ff4500,
    /* Slightly increased from 12px */ 0 0 2px #fff,
    0 0 4px #ffff00,
    0 0 4px #ff4500,
    0 0 6px #ff6347;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mic-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  overflow: hidden;
}
