.team-leaders-select {
  max-width: 150px;
  height: 36px;
  border-radius: 4px;
  background-position: right 8px center;
  &:focus {
    border: none;
  }
}

.close-team-member-icon {
  width: 24px;
  height: 24px;
  background-color: rgb(237, 82, 46);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.move-btn-table {
  padding: 0.3rem 2rem;
  white-space: nowrap;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: rgb(237, 82, 46);
  color: #fff;
}

.check-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-member-table {
  padding: 0;
  tbody {
    tr {
      td {
        &:first-child {
          text-align: start;
          font-size: 14px;
          font-weight: bold;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: auto;
        }
      }
    }
  }
}

.current-team-leader-name {
  font-size: 14px;
}

.move-modal .move-modal-title {
  font-size: 24px;
  text-align: center;
  width: 100%;
}

.current-team-leader-wrapper {
  margin-bottom: 20px;
  text-align: center;
}

.action-modal-team-lead {
  margin-top: 50px;
}

.individual-team {
  tr {
    td {
      &:first-child {
        font-weight: bold;
      }
    }
  }
}
