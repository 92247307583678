.radio-input {
  display: block;
  text-align: left;
}

.input-button {
  margin-left: 10px;
}

.dot-blocker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(237, 82, 46);
  display: inline-block;
  margin-right: 10px;
}

.dot-guidance {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(9, 38, 213);
  display: inline-block;
  margin-right: 10px;
}

.dot-update {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(127, 131, 155);
  display: inline-block;
  margin-right: 10px;
}

.dot-admin {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(154, 51, 255);
  display: inline-block;
  margin-right: 10px;
}
.create-box {
  display: inline-block;
  border: 1.5px solid rgb(224, 224, 224);
  padding: 3px 6px 3px 6px;
  margin-left: 270px;
}
