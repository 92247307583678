.governance-view-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.governance-view-container .meeting-view-row {
  display: flex;
  flex-direction: column;
  width: 65vw;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.enterprise-metrics-container {
  padding: 1rem;
  border-radius: 5px;
  margin: 0 3vw 2vw 0;
  width: 25vw;
}

.enterprise-metrics-container .th {
  font-weight: bold;
}

.enterprise-metrics-container > h3 {
  margin-top: 1rem;
  text-align: center;
}

.enterprise-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.enterprise-metrics > div {
  padding: 1rem 0.5rem;
}

.enterprise-metrics-container .divider {
  margin: 0.5rem 0;
}

.enterprise-metrics b {
  color: rgb(9, 38, 213);
}

html .enterprise-metrics .has-increased {
  color: green;
  font-weight: bolder;
}

html .enterprise-metrics .has-decreased {
  color: red;
  font-weight: bolder;
}

.enterprise-metrics-container .enterprise-metrics-title {
  font-size: 20px;
}

.enterprise-metrics-container .enterprise-metrics-subtitle {
  font-size: 14px;
  width: fit-content;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid rgb(9, 38, 213);
}

.enterprise-metrics-container .divider {
  margin: 2rem 0;
}

.enterprise-metrics-container .enterprise-metrics-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3.5vw;
  font-size: 16px;
  margin: 2rem 0;
}
