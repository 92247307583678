.navigation-new {
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: '1400px';
}

.navigation-new .active-step {
  font-weight: bold;
}

.navigation-new .navigation-new-button a {
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.navigation-new .nav-progress-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-new .progress-bar {
  display: flex;
  align-items: center;
}

.navigation-new .step {
  display: flex;
  align-items: center;
}

.navigation-new .progress-circle {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}

.navigation-new .progress-rect {
  width: 8vw;
  height: 0.4rem;
}

.navigation-new .progress {
  background-color: rgb(225, 225, 225);
}

.navigation-new .active-progress {
  background-color: rgb(9, 38, 213);
}
