html,
body,
#root,
.add-objective {
  height: 100vh;
  width: 100vw;
}

.add-objective {
  // font-family: sans-serif;
  text-align: center;
  /* pointer-events: none; */
  /* width: 800px; */
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

.select-search {
  display: grid;
  grid-template-columns: 250px 150px;
  align-items: flex-start;
}

.custom-select-wrapper-team .validation-error {
  margin-top: 5px;
  display: inline-block;
  font-size: 14px;
}

.select-search {
  position: relative;
}

.select-search .modal-dual-btn {
  margin-top: 0;
  position: absolute;
  left: 250px;
  width: initial;
}

.select-search .modal-dual-btn .dual-btn {
  width: 38px;
  min-width: 38px;
  height: 38px;
  border-radius: 0 4px 4px 0;
  margin: 0;
}

.select-search .modal-dual-btn .dual-btn img {
  width: 20px;
}

/* .text-updater-node {
    height: 50px;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
    background: white;
}

.text-updater-node label {
    display: block;
    color: #777;
    font-size: 12px;
} */

.show-dropdown-data-flow {
  display: flex;
}

.inner-top-header-objective-flow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  box-shadow: 0 5px 15px #ccc;
}

.fliter-list {
  list-style: none;
  margin: 0;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.fliter-list .single-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fliter-list .single-filter span {
  width: 15px;
  height: 15px;
  background-color: gray;
  min-width: 15px;
  min-height: 15px;
}

.fliter-list .single-filter:nth-child(2) span {
  background-color: lightcoral;
}

.fliter-list .single-filter p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: black;
}

.dropdown-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  height: calc(100vh - 182px);
}

.dropdown-column .single-dropdown {
  height: 25%;
}

.filter-by-due-date {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  .filter-due-date-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
    margin: 0;
  }
}

.objective-flow-filter-main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px 10px 20px;
  width: 96%;
}

.filter-date-list {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 1rem;
}

.team-flow-select.filter-by-select {
  max-width: 250px;
}

//activity-table
.activity-table {
  border-collapse: separate;
  width: 100%;
  thead {
    tr {
      th {
        color: blue;
        border-bottom: 1px solid blue;
        padding: 10px;
      }
    }
  }
}

.add-new-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  textarea {
    width: 100%;
    padding: 10px;
  }
}

.message-icon-table {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: blue;
  padding: 5px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.activity-listing {
  list-style: none;
  padding: 0;
  .activity-single {
    text-align: left;
    &:not(:last-child) {
      margin-bottom: 8px;
      border-bottom: 1px solid lightgray;
      padding-bottom: 8px;
    }
    span {
      font-size: 12px;
      color: gray;
      margin-bottom: 4px;
      display: inline-block;
    }
    p {
      font-size: 14px;
      margin: 0;
    }
  }
}

.objective-form {
  &.add-message-objective-flow {
    flex-direction: row;
    .form-field textarea {
      height: 3.5rem;
    }
    .modal-dual-btn {
      width: initial;
    }
  }
}

.react-flow__handle.connectable {
  opacity: 0;
  visibility: hidden;
}
