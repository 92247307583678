.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 101;
  -webkit-transform: translate3d(0, 0, 0);
}

.modal-overlay .modal-container {
  position: relative;
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-overlay .modal-container-padding {
  padding: 0rem 3rem 1rem 3rem;
}

.modal-overlay .modal-container-less-padding {
  padding: 0rem 1.5rem 1rem 1.5rem;
}

.modal-overlay .modal-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
}

.modal-overlay .modal-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  h3 {
    margin: 0;
  }
}
