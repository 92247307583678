.objectives-recommendations-table {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  //row-gap: 1.5rem;
  //column-gap: 2rem;
  position: relative;
  text-align: left;
  width: 100%;
  margin-bottom: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
}

.objectives-recommendations-table-header {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  background-color: rgb(245, 245, 245);
  height: 2.5rem;
  padding-top: 0.5rem;
  color: #4472c4;
  padding-left: 0.5rem;
  padding-right: 1rem;
}

.objectives-recommendations-table-cell {
  display: flex;
  table-layout: fixed;
  width: 100%;
  word-break: break-word;
}

.option-button {
  .recommendations-button {
    background-color: '#a6a6a6';
  }
}
