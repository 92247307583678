.chatbot {
  /* width: 400px; */
  /* height: 500px; */
  height: 0px;
  width: 20vw;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition:
    height 0.3s,
    width 0.3s;
}

.chatbot-header {
  padding: 10px;
  text-align: center;
  color: rgb(255, 255, 255);
  max-height: 25px;
  font-size: 16px;
  font-weight: bold;
  /* background-color: #0926D5; */
  background-color: rgb(68, 114, 196);
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.chatbot-header-clean {
  padding: 10px;
  text-align: center;
  color: rgb(255, 255, 255);
  max-height: 25px;
  font-size: 16px;
  /* font-weight: bold; */
  /* background-color: #0926D5; */
  background-color: rgb(68, 114, 196);
  border-radius: 10px 10px 0 0;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
}

.chatbot-container {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-bottom: 0px;
  padding-right: 10px;

  z-index: 300;
}

.message-bubble {
  max-width: 75%;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 20px;
  word-break: break-word;
}

.user-bubble {
  background-color: #deebf7;
  margin-left: auto;
  margin-right: 5px;
}

.bot-bubble {
  background-color: #f2f2f2;
  margin-right: auto;
  text-align: left;
  margin-left: 20px;
  min-width: 50px;
  margin-left: 0;
}

.message-container {
  /* width: 400px; */
  height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 1.5vh 0px;
}

.expanded-chatbot {
  width: 30vw;
  height: 60vh;
  /* height: 500px; */
  /* width: 430px; */
}
.minimized-chatbot {
  height: 500px;
  max-width: 430px;
}

.hiddenChatbot {
  display: none;
}

.shownChatbot {
  display: block;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes revealDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add these new styles to your existing CSS file */

.bot-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #4472c4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-shrink: 0;
  margin-right: 8px;
}

.bot-avatar-initial {
  font-size: 16px;
  font-weight: 500;
}

.bot-message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: 12px;
}

.bot-content {
  flex: 1;
}

.bot-name-container {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
}

.bot-name {
  font-weight: 600;
  color: #333;
}

.bot-label {
  margin-left: 8px;
  font-size: 0.875rem;
  color: #666;
}
