.buttons-area-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 4rem 0;
  width: 100%;
}

.error-container {
  margin-top: 0;
  height: 0;
}

.dual-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 4rem 0;
  gap: 20rem;
}

.buttons-area-container .next {
  right: 1.5rem;
}

.buttons-area-container .previous {
  left: 1.5rem;
}

.buttons-area-container .previous {
  transform: scaleX(-1);
}
