.member-name {
  font-size: 16px;
  color: black;
  font-weight: 700;
  display: block;
  margin-bottom: 8px;
}

.total-row {
  td {
    cursor: pointer;
    &:first-child {
      font-size: 16px;
      color: rgb(237, 82, 46);
      font-weight: 700;
    }
  }
}

.table-objective-mapping-head {
  th {
    cursor: pointer;
  }
}

.custom-input {
  &.border-change {
    border: 1px solid rgb(237, 82, 46);
    width: 5vw;
    padding: 0.75rem 0.5rem;
    &:disabled {
      border: none;
    }
  }
}

.field-column {
  text-align: center;
}

.capacity-cell {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.dist-btn {
  width: fit-content;
}

.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  color: red;
  border: 1px solid red;
  padding: 0.5rem;
  font-size: 12px;
  margin: 0;
  font-weight: 700;
  span {
    color: black;
  }
}

.total-number {
  text-align: center;
}

//commented out while style of table is finalized
// .objective-mapping-tbody-row {
//     background-color: whitesmoke;
// }

.active-column {
  background-color: whitesmoke;
}

.member-name-btn {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
}

.team-cap-allocation-name-cell {
  width: 10vw;
}

.team-cap-allocation-generic-cell {
  width: 10vw;
}

.team-cap-allocation-total-cell-th {
  width: 6vw;
}

.team-cap-allocation-total-cell-td {
  width: 6vw;
  height: 2.9vw;
  padding: 0.75rem 0.5rem;
}

.team-capacity-table {
  td {
    padding: 0 1rem 0.5rem 0;
  }
  th {
    padding: 0 1rem 0.5rem 0;
  }
  th span {
    font-size: 16px;
  }
  td span {
    font-size: 16px;
  }
}
