.enterprise-step .enterprise-instructions {
  width: 45vw;
  margin: 0;
}

.enterprise-step .instruction {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.enterprise-step .enterprise-goal-select,
.enterprise-step .exec-goal-select {
  width: 100%;
}

.enterprise-step table {
  width: 86vw;
}

.enterprise-step table tr {
  width: 76vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.enterprise-step table .wide-cell {
  width: 20vw;
}

.enterprise-step table th,
.enterprise-step table td {
  width: 15vw;
}

.enterprise-step table select,
.enterprise-step table .enterprise-goal-select {
  max-width: none;
  width: 90%;
}

.enterprise-step th,
.enterprise-step .help-header {
  justify-content: flex-start;
}
