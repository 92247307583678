body {
  margin: 0px;
}
.slider-wrapper {
  display: grid;
  grid-template-columns: 1fr 20rem;
  grid-gap: 1%;
  padding: 1rem;
  background-color: #222;
  height: 95vh;
  width: 100%;

  .slide-container {
    // box-shadow: 0 5px 15px #ccc;
    // padding: 1rem 1rem;
  }
  .pager {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;

    li {
      list-style-type: none;
    }
  }
  .Card {
    // box-shadow: 0 5px 15px #ccc;
    // width: 100%;
    min-width: auto;
    z-index: 1;
    border: none;
    .body_text {
      word-break: break-word;
    }
    .card_footer {
      .card_footer_rightside {
        flex: 1;
        justify-content: space-between;
      }
    }
  }

  .canvas_box {
    width: 100% !important;
    height: auto !important;
  }
  .comments-wrapper {
    // padding-top: 20px;
    // width: 300px;
    // background-color: red;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    gap: 1rem;
    padding: 1rem;

    .btn-box {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      button {
        flex: 1;
      }
    }
    .card-header {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
    }
    .comments-subBox {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 8px;
      gap: 8px;
      span {
        flex: 1;
      }
    }
    .add-btn-box {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }
}
