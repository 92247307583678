.manage-leaders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
}

.heading {
  font-size: 20px;
}

.wrap-text {
  overflow-wrap: break-word;
}

.underline-admin::after {
  content: '';
  background: blue;
  transform: skew(-180deg);
  z-index: -1;
  height: 2px;
  width: 100%;
  position: absolute;
  margin: auto;
}
