.review-generated-topics-modal {
  .modal-container {
    text-align: left;
    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
}

.review-generated-topics-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 0.8rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 100%;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 8px;
  /* word-wrap: break-word; */
  word-break: break-word;
}

.odin-mention-input-box {
  word-break: break-word;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}

.odin-mention-input-box-body_bottom_text {
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  background-color: rgb(242, 242, 242);
}

.review-generated-topics-list-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  background-color: rgb(245, 245, 245);
  height: 2.5rem;
  padding-top: 0.5rem;
  color: #4472c4;
}
.review-generated-topics-list-item {
  display: flex;
  align-items: center;
}

.review-generated-topics-list-item textarea {
  min-height: 2.5rem;
  height: 2.5rem;
  resize: none;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 2.5px;
  word-break: break-word;
  font-family: 'Open Sans', Arial, Helvetica, 'system-ui', sans-serif;
  width: 90%;
}

.review-generated-topics-list-table-cell {
  padding: 0 0.5rem 0.5rem 0;
}

.review-generated-topics-list-row {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
}

.review-generated-objective-employee-list-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.review-generated-actions-comments-list-row {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1fr;
}

.odin-email-detail-text {
  text-align: left;
  color: black;
}

.action-box.blocker {
  background-color: #ed522e;
}

.action-box.guidance {
  background-color: #0926d5;
}

.action-box.update {
  background-color: rgb(127, 131, 155);
}

.review-generated-topics-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
