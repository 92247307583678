.setup-wizard-component .step-section h2 {
  width: 60%;
  /* margin: 2rem 0; */
}

.setup-wizard-component .step-details-p {
  margin-bottom: 3rem;
  font-size: 18px;
  width: 60%;
  text-align: center;
}

.setup-wizard-component .step-details-intro-point-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
}

.setup-wizard-component .step-details-intro-point-container:last-of-type {
  margin-bottom: 1rem;
}

.setup-wizard-component .step-details-intro-point-container img {
  width: 35px;
  height: 35px;
  margin-right: 1rem;
}

.setup-wizard-component .submit-btn-container {
  position: relative;
}

.setup-wizard-component .submit-btn-container:hover .submit-error-message-container {
  display: flex;
}

.setup-wizard-component .submit-btn-container .submit-error-message-container {
  bottom: 7vh;
  left: -20vw;
  width: 40vw;
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 0, 0);
}
