.kpi-updates-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
  position: relative;
  text-align: left;
}

.kpi-updates-table-no-doc {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
  position: relative;
  text-align: left;
}

.kpi-updates-table-header {
  font-weight: bold;

  font-size: 16px;
  text-align: left;
}
