.gif-container {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.gif-title {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}

.gif-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-items: flex-start;
}

.gif-row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.number-badge-container {
  padding-top: 5rem;
  height: 100%;
}

.number-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #3b82f6;
  color: white;
  border-radius: 50%;
  font-weight: bold;
}

.gif-image {
  max-width: 350px;
  border-radius: 8px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
