.info-modal2 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 3;
}

.info-modal2 .modal-tf {
  position: relative;
  width: 50vw;
  background-color: #fff;
  padding: 2vw;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  overflow-y: auto;
}

.info-modal2 .form {
  width: fit-content;
  align-items: flex-start;
}

.info-modal2 .form button {
  align-self: center;
}

.info-modal2 .divider:first-of-type {
  margin-bottom: 1rem;
}

.info-modal2 .divider:last-of-type {
  margin-top: 1rem;
}

.info-modal2 ul {
  text-align: left;
}
