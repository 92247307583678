.search-objectives-modal-outer-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.search-objectives-dropdown-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 30rem;
}

.search-objectives-buttons-container {
  display: flex;
  justify-content: right;
  margin-top: 1rem;
  padding-right: 1rem;
}

.search-objectives-dropdown-label {
  font-weight: bold;
}
