$sideMenuWidthOpen: 228px;
$sideMenuWidthClosed: 75px;

.navigation {
  //position: fixed;
  //z-index: 99;
  //top: 0;
  //left: 0;
  background-color: white;
  //overflow-x: hidden;
  transition: 0.5s;
  //padding-top: 60px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid rgb(238, 238, 238);
  font-size: 14px;
  min-height: 100vh;
  position: fixed;
}

.navigation-top {
  position: fixed;
  //top:0;
  display: flex;
  width: 100%;
  height: 45px;
  background-color: white;
  z-index: 99;
  border-bottom: 1px solid rgb(238, 238, 238);
  transition: 0.5s;
}

.navigation-open {
  width: $sideMenuWidthOpen;
}

.navigation-closed {
  width: $sideMenuWidthClosed;
}

.navigation .navigation-button {
  font-size: 18px;
  cursor: pointer;
  height: 4vh;
  padding: 0 0.5rem;
}

.navigation .navigation-button .next {
  width: 15px;
  height: 15px;
  margin-left: 2rem;
}

.navigation .active-step {
  font-weight: bold;
}

.navigation .navigation-button a {
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.active-step a {
  border-bottom: 2px solid rgb(9, 38, 213);
}

.logo-container {
  background-color: white;
  height: 45px;
  //padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-right: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid rgb(238, 238, 238);
  //padding-left: 0.5rem;
}

.logo-container-closed {
  width: $sideMenuWidthClosed;
}

.logo-container-open {
  width: $sideMenuWidthOpen;
}

.logo {
  width: 2rem;
  height: 2rem;
}

.logo-large {
  width: 6rem;
  height: 6rem;
}

.logo-large-new {
  width: 22rem;
  height: 6rem;
}

.logo-new {
  width: 9rem;
  height: 2.5rem;
}

.logo-new-folded {
  width: 4rem;
  height: 4rem;
}

.objective_map_sample {
  width: 30rem;
  height: 25rem;
}

.app-name {
  height: 1rem;
}

.navigation .nav-progress-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation .progress-bar {
  display: flex;
  align-items: center;
}

.navigation .step {
  display: flex;
  align-items: center;
}

.navigation .progress-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.navigation .progress-rect {
  width: 150px;
  height: 5px;
}

.app-content {
  top: 46px;
  height: 100%;
  display: flex;
  position: relative;
}

.navigation .progress {
  background-color: rgb(225, 225, 225);
}

.navigation .active-progress {
  background-color: rgb(9, 38, 213);
}

.navigation .avatar-container {
  width: 6vw;
  height: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation .avatar {
  margin: 0 1rem;
  height: 3.5vw;
  border-radius: 50%;
  border: 1px solid rgb(238, 238, 238);
  cursor: pointer;
}

.navigation .show-navigation-button {
  height: 35px;
  cursor: pointer;
  transition: all 250ms;
}

.navigation .show-navigation-button:hover {
  transform: scale(1.05);
}

.nav-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-link-closed {
  justify-content: center;
}

.nav-link-container {
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  gap: 1rem;
  font-weight: 600;
}

.nav-link-container-closed {
  padding-left: 0rem;
  justify-content: center;
}

.nav-link-container-top {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.navigation-toggle {
  display: flex;
  cursor: pointer;
  z-index: 100;
  align-items: center;
  color: rgb(176, 173, 173);
}

.nav-link-container > a > div {
  cursor: pointer;
}

.logoutIcon {
  width: 2rem;
  height: 2rem;
}

.menu-button-p :hover {
  color: rgb(9, 38, 213);
}

.content-container-closed {
  width: calc(100% - $sideMenuWidthClosed);
  margin-left: $sideMenuWidthClosed;
}

.content-container-open {
  width: calc(100% - $sideMenuWidthOpen);
  margin-left: $sideMenuWidthOpen;
}

.top-menu-right-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.top-menu-right-side-open {
  width: 100%;
  // width: calc(100% - $sideMenuWidthOpen);
}

.top-menu-right-side-closed {
  width: 100%;
  //width: calc(100% - $sideMenuWidthClosed);
}

.navigation-menu {
  .szh-menu {
    z-index: 10000;
    font-family: sans-serif;
    font-size: 0.925rem;
    user-select: none;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 8px;
    min-width: 10rem;
    font-size: 14px;

    &--state-opening {
      animation: szh-menu-show 0.15s ease-out;
    }

    &--state-closing {
      animation: szh-menu-hide 0.2s ease-out forwards;
    }
    &__item {
      border-radius: 6px;
      padding: 0.5rem 1rem;
      font-weight: 400;
      color: '#425066';
      font-size: 14px;

      &--hover {
        color: rgb(9, 38, 213);
        background-color: white;
      }
      &--submenu {
        position: relative;
        &::after {
          position: absolute;
          width: 7px;
          right: 0.625rem;
        }
      }
    }
  }
}
