.form-field {
  &.action-field {
    &--add-subtask {
      display: grid;
      grid-template-columns: 1fr auto 91px;
      gap: 30px;
      .custom-select {
        min-width: 150px;
      }
    }
  }
}

.form-field {
  &.action-field {
    &--multi-subactivity {
      display: flex;
      flex-direction: row;
      justify-content: left;
      column-gap: 2rem;
      .talk-text {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin-top: 2rem;
      }
    }
  }
}
