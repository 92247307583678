.node-team-flow.custom-node-team-flow {
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  // font-size: 12px;
  color: #222;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #1a192b;
  background-color: white;
  font-size: 9px;
  &.add-blue {
    background-color: #0084ff;
    color: white;
  }
  &.without-blue {
    color: black;
    background-color: white;
  }
  .active-wizard-title {
    margin: 0;
  }
}

.active-wizard-btn {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 6px;
  border: none;
  background: rgb(237 82 46);
  color: white;
  border-radius: 2px;
  padding: 2px 2px;
}

div.customHandle {
  width: 100%;
  height: 100%;
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  transform: none;
  border: none;
  opacity: 0;
}

.customNode:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  height: 10px;
  width: 20px;
  transform: translate(-50%, 0);
  background: #d6d5e6;
  z-index: 1000;
  line-height: 1;
  border-radius: 4px;
  color: #fff;
  font-size: 9px;
  border: 2px solid #222138;
}
