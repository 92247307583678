.impact-measure-custom-table-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.impact-measure-custom-table-component .impact-measure-table-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
}

.impact-measure-custom-table-component .impact-measure-table-wrapper .add-btn {
  width: 14vw;
}

.impact-measure-custom-table-component table {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
