.impact-measures-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.impact-measures-component .modal-tf p {
  width: 60%;
}

.impact-measures-component .modal-tf p:last-of-type {
  margin-bottom: 2rem;
}
