.list-wrapper-comment {
  width: 100%;
  p {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
  }
}

.comment-create-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    &:nth-child(2) {
      color: gray;
    }
  }
}
