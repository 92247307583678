.my-decisions-meeting-table {
  display: grid;
  grid-template-columns: 5px 1.2fr 2fr;
  grid-gap: 0.5rem 1rem;
}

.my-decisions-meeting-table-new-format {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.1rem 0rem;
}

.my-decisions-meeting-header {
  font-weight: bold;
  padding-left: 1rem;
}

.my-decisions-meeting-header-new-format {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  background-color: rgb(245, 245, 245);
  height: 2.5rem;
  padding-top: 0.5rem;
  color: #4472c4;
}

.my-decisions-meeting-description {
  grid-column: 1 / 4;
  font-weight: bold;
}

.meeting-topic-blocker {
  /* border-left: 0.2rem solid #D85959; */
  /* padding: 0rem 0rem 1rem 1rem; */
}
.meeting-topic-decision {
  /* border-left: 0.2rem solid blue; */
  /* padding: 0rem 0rem 1rem 1rem; */
}
.meeting-topic-update {
  /* border-left: 0.2rem solid gray; */
  /* padding: 0rem 0rem 1rem 1rem; */
}
.meeting-topic-admin {
  /* border-left: 0.2rem solid #9933FF; */
  /* padding: 0rem 0rem 1rem 1rem; */
}

.my-decisions-comment {
  margin-bottom: 0.5rem;
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-top: 1.5rem;
}
.my-decisions-milestone {
  margin-bottom: 0.5rem;
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.date-filter {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.date-validation-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0rem 2rem 2rem auto;
  align-items: flex-end;
}

.validation-message {
  color: red;
  margin-right: 12%;
}
.my-decisions-inner-grid {
  display: grid;
  grid-template-columns: 3fr 5fr 1fr 2fr 2fr 1.5fr 0.5fr 0.1fr;
}

.my-decisions-history-inner-grid {
  display: grid;
  grid-template-columns: 5fr 1.5fr 1.5fr;
  grid-gap: 0.5%;
}

.my-decisions-inner-grid-classification {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2%;
}

.my-decisions-meeting-table-classification {
  display: grid;
  grid-template-columns: 20px 1fr 1.5fr;
  grid-gap: 1.5rem;
}

.my-decisions-meeting-description-classification {
  grid-column: 1 / 4;
  font-weight: bold;
}

.my-decisions-cell {
  text-align: left;
  padding-left: 0.3rem;
  margin-top: 1.5rem;
}

.my-decisions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 0.8rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 100%;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 8px;
  /* word-wrap: break-word; */
  word-break: break-word;
}
