.edit-btn,
.delete-btn {
  width: 22px;
  height: 22px;
  margin-left: 1rem;
  cursor: pointer;
  position: relative;
  bottom: 0.5rem;
}

.enterprise-objective-step-component .form-field {
  margin-bottom: 1.5rem;
}

.enterprise-objective-step-component .form-field label {
  margin-bottom: 0.75rem;
}

.enterprise-objective-step-component .form-field:first-of-type {
  margin-top: 1rem;
}

.enterprise-objective-step-component .form-field input,
.enterprise-objective-step-component .form-field #verb,
.enterprise-objective-step-component .form-field #descriptor_input {
  width: 16vw;
  padding: 0 1vw;
  height: 35px;
}

.enterprise-objective-step-component .form-field select {
  width: 18vw;
}

.enterprise-objective-step-component .goal-container {
  min-width: 40vw;
}
