.add-goal-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 3;
}

.add-goal-modal .modal-tf {
  position: relative;
  width: 50vw;
  background-color: #fff;
  padding: 2vw;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  overflow-y: auto;
}

.add-goal-modal .form {
  width: fit-content;
  align-items: flex-start;
}

.add-goal-modal .form button {
  align-self: center;
}

.add-goal-modal .divider:first-of-type {
  margin-bottom: 1rem;
}

.add-goal-modal .divider:last-of-type {
  margin-top: 1rem;
}
#new_obj_verb_txt {
  width: 10rem;
}

.objective-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.modal-dual-btn .dual-btn {
  margin: 0 1rem;
  padding: 0.5rem 0;
  width: 10vw;
  border: none;
  border-radius: 2.5px;
  cursor: pointer;
  font-size: 14px;
}

.modal-dual-btn .positive-btn {
  background-color: rgb(237, 82, 46);
  /* new color to be used rgb(9, 38, 213, 0.8); */
  color: #fff;
  opacity: 0.5;
}

.modal-dual-btn .negative-btn {
  background-color: rgb(200, 200, 200, 0.8);
}
