/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  overflow-x: hidden;
}

.checkbox-wrapper label {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: grey;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.checkbox-wrapper label a {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: black;
  display: inline-block;
}

.checkbox-wrapper input + label {
  cursor: pointer;
  user-select: none;
  margin-left: 29px;
}

.checkbox-wrapper input + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: white;
}

.checkbox-wrapper input:checked + label:before {
  background: url('./assets/checkbox-tick.svg') rgb(237, 82, 46);
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
  border: 1px solid rgb(237, 82, 46);
  border-radius: 3px;
}

.custom-input {
  // border: 1px solid transparent;
  &:focus {
    border: 1px solid rgb(237, 82, 46);
    outline: none;
  }
  &:disabled {
    background-color: lightgray;
  }
}

.btn {
  padding: 0.3rem 0.5rem;
  white-space: nowrap;
  border: none;
  border-radius: 2.5px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &.btn-add {
    background-color: #0926d5;
    color: #fff;
    img {
      width: 10px;
    }
    &:hover {
      background-color: #5970f8;
    }
  }
}

[data-rbd-drag-handle-context-id='0'] {
  cursor: initial !important;
  // cursor: initial !important;
}

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }

// input[type=number] {
//     -moz-appearance: textfield;
// }
