.comments-menu {
  .szh-menu {
    font-family: sans-serif;
    font-size: 0.925rem;
    user-select: none;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 8px;
    min-width: 14rem;

    &--state-opening {
      animation: szh-menu-show 0.15s ease-out;
    }

    &--state-closing {
      animation: szh-menu-hide 0.2s ease-out forwards;
    }
    &__item {
      border-radius: 6px;
      padding: 0.1rem 0.5rem;
      &--hover {
        color: #fff;
        background-color: #59a2ff;
      }
      &--submenu {
        position: relative;
        &::after {
          position: absolute;
          width: 7px;
          right: 0.625rem;
        }
      }
    }
  }
}
