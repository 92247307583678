.blue_class {
  --dsg-cell-background-color: rgb(255, 255, 255);
}

.spread_sheet_cell_warning {
  --dsg-cell-background-color: rgb(237, 209, 209);
}

.spreadsheet-action-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  align-self: flex-end;
}

.move-btn-table {
  &.file-import-btn {
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
    }
  }
}

.success {
  &.success-add-commnet {
    font-size: 12px;
    line-height: 16px;
    color: rgb(0, 255, 21);
    margin-top: 4px;
  }
}
.trash_btn {
  padding: 11px;
  cursor: pointer;
  img {
    filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%)
      contrast(117%);
  }
}
.btn_cmn {
  border-radius: 5px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  width: 10vw;
  border: none;
  font-size: 13px;
}
.import_export_btn {
  background-color: rgb(237, 82, 46);
}
.label-custom {
  font-size: 14px;
  font-weight: 400;
}
