.fte-warning-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 3;
}

.fte-warning-modal .modal-tf {
  position: relative;
  width: 50vw;
  background-color: #fff;
  padding: 2vw;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  overflow-y: auto;
}

.fte-warning-modal .form {
  width: fit-content;
  align-items: flex-start;
}

.fte-warning-modal .form button {
  align-self: center;
}

.fte-warning-modal .divider:first-of-type {
  margin-bottom: 1rem;
}

.fte-warning-modal .divider:last-of-type {
  margin-top: 1rem;
}

.fte-warning-modal .modal-title {
  margin-bottom: 2rem;
}

.fte-warning-modal .nav-link {
  font-size: 22px;
  margin-bottom: 2rem;
  cursor: pointer;
}

.fte-warning-modal .nav-link:hover {
  color: rgb(246, 145, 60);
}

.fte-warning-modal .capacity-checkbox {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.fte-warning-modal p {
  width: 80%;
  background-color: rgba(255, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 5px;
}

.fte-warning-modal h2 {
  margin: 0 0 1rem 0;
}

.fte-warning-modal table {
  padding: 1rem;
}

.fte-warning-modal tr {
  width: auto !important;
}
