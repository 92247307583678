.modal-gray-box {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.modal-inner-content {
  margin: 1rem;
}

.tutorial-management-modal {
  .modal-container {
    text-align: left;
    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
}

.tutorial-management-modal-title {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 25px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  color: rgb(94, 134, 204);
}

.tutorial-management-modal-text {
  text-align: left;
  color: black;
  margin-bottom: 15px;
}

.tutorial-management-modal-text-unavailable {
  text-align: left;
  color: rgb(175, 175, 175);
  margin-bottom: 15px;
}

.custom-checkbox {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgb(152, 152, 152);
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: rgb(0, 117, 255);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
  border-color: white;
  height: 15px;
  left: 30%;
  top: 5%;
}

.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
