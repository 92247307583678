/* REPONSIVENESS DEMO */

@media (min-width: 1900px) and (orientation: landscape) {
}

/* targetting 1536 x 864 resolution */
@media (min-width: 1400px) and (max-width: 1899px) and (orientation: landscape) {
  html .setup-wizard-component .enterprise-leader-setup-step .add-btn {
    width: 16vw;
  }
}

/* targetting 1366 x 768 resolution */
@media (min-width: 1001px) and (max-width: 1400px) and (orientation: landscape) {
  html .setup-leader-intro .intro-summary-container .intro-summary-point {
    height: 50vh;
  }

  html .setup-wizard-component .enterprise-leader-setup-step .add-btn {
    width: 16vw;
  }
}
