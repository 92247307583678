.content {
  overflow-x: auto;
  height: auto; /* Adjust as needed */
  overflow-y: hidden;
}

.fake-scrollbar {
  overflow-y: hidden;
  overflow-x: auto;
  height: 20px; /* This height should match the height of the browser's scrollbar */
}

.fake-scrollbar.sticky {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 67vw;
}

.fake-scrollbar > div {
  height: 1px;
}

.content::-webkit-scrollbar-thumb {
  /* background: transparent;
     */

  background-color: transparent;
}

.content::-webkit-scrollbar-track {
  /* background: transparent;
     */

  background-color: transparent;
}

.content::-webkit-scrollbar {
  /* background: transparent;
     */
  display: none;
  background-color: transparent;
}
