.impact-measures-intro-component h2 {
  margin: 2rem 0 3rem 0;
  max-width: none;
  text-align: left;
}

.impact-measures-intro-component .step-details-intro-points-container {
  width: 100%;
  margin: 2rem 0;
}

.impact-measures-intro-component .step-detail-sub-point {
  position: relative;
  left: 4vw;
}

.impact-measures-intro-component .step-detail-sub-point img {
  width: 25px;
  height: 25px;
}
