@media (min-width: 1001px) and (max-width: 1400px) and (orientation: landscape) {
  html select {
    max-width: none;
  }

  /* html .navigation {
    width: 94vw;
    padding: 0 2vw;
    height: 10vw;
  } */

  /* html .logo-container {
    width: 10vw;
    height: 10vw;
  }

  html .navigation .logo {
    width: 10vw;
    height: calc(10vw * (5 / 6));
  } */

  html .navigation .show-navigation-button {
    width: 40px;
    height: 40px;
    margin-left: 0.75rem;
  }

  html .navigation .avatar,
  html .navigation .avatar-container {
    width: 6vw;
    height: 6vw;
  }

  html .navigation-new .progress-circle {
    width: 1.75vw;
    height: 1.75vw;
  }

  html .navigation-new .progress-rect {
    width: 10vw;
    height: 5px;
  }

  html .intro-summary-container {
    width: 85vw;
  }

  html .intro-summary-container .intro-summary-point {
    width: 25vw;
    padding: 2rem 0;
    font-size: 18px;
  }

  html .intro .start-button {
    width: 30vw;
    margin-bottom: 2rem;
  }

  .team-step table th,
  .team-step table td {
    width: 20vw;
  }

  html .team-step .time-input {
    width: 6vw;
    font-size: 12px;
  }

  html .add-btn,
  html .edit-cap-btn {
    width: 15vw;
    font-size: 14px;
  }

  /* html .dual-button-container {
    width: 100vw;
    padding-bottom: 2rem;
  } */

  html .dual-button-container .dual-btn {
    width: 15vw;
  }

  html body .objectives-step .edit-btn {
    width: 15px;
    height: 15px;
  }

  html body .objectives-step .delete-btn {
    width: 15px;
    height: 15px;
  }

  .objectives-step .goal-container {
    width: 40vw;
    padding: 2vw;
  }

  html .objectives-step .goal-input {
    width: 30vw;
    font-size: 14px;
    padding: 0 2vw;
  }

  html .obj-intro-modal .modal-tf {
    padding: 4vw;
  }

  html .activites-step select {
    width: 16vw;
  }

  html .trade-off-card {
    width: 30vw;
    min-width: 30vw;
  }
}
