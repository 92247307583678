.filing-cabinet-table {
  display: grid;
  grid-template-columns: 1fr 0.8fr 0.5fr 0.5fr 0.5fr 0.2fr;
  row-gap: 0.3rem;
  column-gap: 1rem;
}

.filing-cabinet-header {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}
