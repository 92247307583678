.transition-div {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition:
    opacity 0.1s ease-out,
    max-height 0.1s ease-out;
  animation-direction: reverse;
}

.transition-div.visible {
  opacity: 1;
  max-height: 100%;
}

.transition-mh-div {
  opacity: 0;
  max-height: 0;
  transition:
    opacity 0.1s ease-out,
    max-height 0.1s ease-out;
  animation-direction: reverse;
}

.transition-mh-div.visible {
  opacity: 1;
  max-height: 100%;
}

.floatingKanbanColumnTitles {
  width: 100%;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.floatingKanbanColumnTitles.sticky {
  position: fixed;
  // top: 60px; /* Height of the sticky header */
  // left: 0;
  // right: 0;
  z-index: 999;
}
