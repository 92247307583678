.container {
  padding-top: 5rem;
}

.objective_wrapper {
  display: flex;
  justify-content: center;
  column-gap: 25px;
  row-gap: 24px;
  width: 95%;
  margin-left: 0.5rem;
  margin-right: 1rem;
  .card {
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-shadow: 0 5px 15px #ccc;
    padding: 1rem 2rem;
    flex: 1;
    color: #44546a;
    .card_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      .card_title {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        margin: 0px;
      }
    }
    .btn_link {
      color: blue;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
    }
    .card_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px;
      width: 30px;
      height: 30px;
      border-radius: 10px;
      background-color: blue;
      color: white;
      font-weight: bold;
    }
    .Team_card_body {
      display: flex;
      flex-direction: column;

      .card_item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-bottom: 1px solid rgba(184, 181, 181, 0.2784313725);
        padding: 16px 0px;

        &_heading {
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
        }
        &_body {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .owner_name {
            font-size: 16px;
            font-weight: 200;
            line-height: 22px;
            font-style: italic;
          }
          .btn_box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            .cross_btn {
              background-color: red;
            }
          }
        }
      }
      .view_all {
        color: blue;
        cursor: pointer;
        margin-top: 16px;
      }
    }
    .Members_card_body {
      table {
        display: table;
        width: 100%;
        padding: 0px;
        border-collapse: collapse;
      }
      tr {
        display: table-row;
      }
      td,
      th {
        width: auto;
        &:first-child {
          // color: red;
          padding-left: 0px;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid rgba(184, 181, 181, 0.2784313725);
        }
        .table_comments {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0px;
        }
      }
      .comments {
        width: 10vw;
        max-width: 10vw;
        padding-right: 0px;
      }
    }
  }
}

.btn_box_red {
  color: white !important;
  background-color: red !important;
}

.btn_box .btn-common {
  padding: 0.5rem 0.5rem;
}
