.add-task-des-wrapper {
  background-color: rgb(242, 242, 242);
  padding: 10px;
  font-size: 16px;
  line-height: 22px;
  color: black;
  border-radius: 4px;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 600;
}
