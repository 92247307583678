@keyframes infinite-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.right-panel.collapsed {
  width: 10%;
  /* background-color: #d3d3d3; */
  transition: all 0.5s;
  /* height: 45rem; */
}

.right-panel.expanded {
  width: 30%;
  margin-right: '2rem';
}

.brainstorm-sessions-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 0.3rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 95%;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 8px;
  word-wrap: break-word;
}

.brainstorm-sessions-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  gap: 0.2rem;
}

.chatbot-team-performance-item {
  margin-top: auto;
  margin-bottom: auto;
}

.brainstorm-sessions-row-hide-tag {
  display: grid;
  grid-template-columns: 0.7fr 0.4fr 0.4fr;
  gap: 0.2rem;
}

.brainstorm-sessions-row {
  display: grid;
  grid-template-columns: 0.7fr 0.4fr 0.4fr 0.4fr;
  gap: 0.2rem;
}
