.staffing-table {
  display: grid;
  grid-template-columns: 1.5rem 2fr 1fr;
  grid-gap: 1rem;
}

.staffing-tabl-header {
  font-weight: bold;
  padding-left: 1rem;
  font-size: 20px;
  text-align: left;
}

.topic-btn {
  width: fit-content;
  height: fit-content;
  margin-left: 2rem;
}

.staffing-table-statement {
  width: 30rem;
  text-align: left;
}
.staffing-table-stuff {
  width: 15rem;
  text-align: left;
}
