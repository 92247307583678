@media (max-width: 1000px) and (orientation: landscape) {
  html select {
    max-width: none;
  }

  /* html .navigation {
    width: 96vw;
    padding: 0 1vw;
    height: 12vw;
  } */

  /* html .logo-container {
    width: 12vw;
    height: 12vw;
  } */

  /* html .navigation .logo {
    width: 12vw;
    height: calc(12vw * (5 / 6));
  } */

  html .navigation .show-navigation-button {
    width: 30px;
    height: 30px;
    margin-left: 0.75rem;
  }

  html .navigation .avatar,
  html .navigation .avatar-container {
    width: 7vw;
    height: 7vw;
  }

  html .navigation-new .progress-circle {
    width: 2.5vw;
    height: 2.5vw;
  }

  html .navigation-new .progress-rect {
    width: 10vw;
    height: 5px;
  }

  html .intro-summary-container {
    width: 96vw;
  }

  html .intro-summary-container .intro-summary-point {
    width: 30vw;
    height: 105vh;
    padding: 2rem 0;
    max-height: none;
  }

  html .dual-button-container button {
    width: 30vw;
    margin-bottom: 2rem;
  }

  html h2 {
    font-size: 22px;
  }

  html table th span {
    font-size: 14px;
  }

  html table td {
    font-size: 12px;
  }

  html tr {
    width: 75vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .team-step table th,
  .team-step table td {
    width: 25vw;
  }

  .team-step table td {
    width: 25vw;
  }

  html .team-step .time-input {
    width: 10vw;
    font-size: 12px;
  }

  html .add-btn,
  html .edit-cap-btn {
    width: 30vw;
    font-size: 14px;
  }

  /* html .dual-button-container {
    width: 100vw;
    padding-bottom: 2rem;
  } */

  html .dual-button-container .dual-btn {
    width: 30vw;
  }

  html .add-employee-modal .modal-tf,
  html .add-goal-modal .modal-tf {
    width: 70vw;
    padding: 2rem 2vw;
  }

  html .add-employee-modal .modal-tf .modal-title,
  html .add-goal .modal-tf .modal-title {
    font-size: 22px;
  }

  html .add-employee-modal .modal-tf .modal-title-container img,
  html .add-goal-modal .modal-tf .modal-title-container img {
    width: 35px;
    height: 35px;
  }

  html .modal-tf .form-field label {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  html .modal-tf .form-field input {
    width: 46vw;
    padding: 2vw;
    font-size: 14px;
  }

  html .modal-dual-btn {
    width: 100%;
  }

  html .modal-dual-btn .dual-btn {
    width: 22vw;
    margin: 0 0.75rem;
  }

  html .objectives-step .objective-instructions {
    width: 70vw;
  }

  html .objectives-step .objective-instructions img {
    width: 25px;
    height: 25px;
  }

  html .objectives-step .objective-instructions span {
    font-size: 14px;
  }

  html .add-goal-modal .modal-tf .bullet-container {
    width: 50vw;
  }

  html .modal-tf .bullet-container b {
    font-size: 16px;
  }

  html .modal-tf .bullet-container span {
    font-size: 12px;
  }

  html #descriptor_input,
  html #verb {
    width: 46vw;
    padding: 2vw;
    font-size: 14px;
  }

  html .goal {
    margin-bottom: 1rem;
  }

  html body .objectives-step .edit-btn {
    width: 15px;
    height: 15px;
  }

  html body .objectives-step .delete-btn {
    width: 15px;
    height: 15px;
  }

  .objectives-step .goal-container {
    width: 60vw;
    padding: 4vw;
  }

  html .objectives-step .goal-input {
    width: 50vw;
    font-size: 14px;
    padding: 0 2vw;
  }

  html .obj-intro-modal .modal-tf {
    width: 80vw;
    padding: 6vw;
  }

  html .activites-step table {
    padding: 1vw;
  }

  html .activites-step table {
    padding: 1vw;
  }

  html .activites-step th,
  html .activites-step td {
    width: 40vw;
  }

  html .activites-step th,
  html .activites-step td {
    width: 40vw;
  }

  html .activites-step select {
    width: 26vw;
  }

  html .objectives-step .objective-instructions {
    width: 70vw;
  }

  html .objectives-step .objective-instructions span {
    font-size: 14px;
  }

  html .enterprise-step table span {
    font-size: 10px;
  }

  html .enterprise-step table .header-help-btn {
    width: 15px;
    height: 15px;
  }

  html .enterprise-step table {
    padding: 0;
    width: 96vw;
  }

  html .enterprise-step tr {
    width: 90vw;
  }

  html .enterprise-step th,
  html .enterprise-step td,
  html .enterprise-step .wide-cell {
    width: 22vw;
  }

  html .blocking-instructions {
    width: 70vw;
  }

  html .blocking-instructions span {
    font-size: 14px;
  }

  .fte-warning-modal .modal-tf {
    width: 70vw;
  }

  html .meetingview-view-body {
    flex-direction: column-reverse;
  }

  html .scorecard-menu {
    position: unset;
    right: unset;
    width: 100%;
  }

  html .scorecard-menu.expanded {
    max-width: 100%;
    min-width: unset;
    width: 100%;
  }

  /* html .meetingview-view-body .scorecard-main-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  } */

  html .meetingview-view-body .scorecard-container {
    width: 80vw;
    margin: 0 0 4vw 0;
  }

  html .meetingview-view-body .scorecard-container .scorecard-header-container {
    padding-right: 6vw;
  }

  /* html .meeting-view-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  html .meeting-view-container .meeting-view-row {
    width: 90vw;
  }

  html .meeting-view-row h3 {
    text-align: center;
  }

  html .blocked-card-container {
    flex-direction: column;
    overflow-x: hidden;
  }

  html .blocked-card {
    width: 60vw;
    padding: 4vw;
    margin: 0 0 4vw 0;
    height: auto;
    /* position: relative; might necessary leave for now */
    right: 1.5vw;
    max-width: 15rem;
  }

  html .meetingview-nav-container .nav-option {
    text-align: center;
  }

  /* html .trade-offs {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } */

  html .trade-off-container {
    flex-direction: column;
    width: 90vw;
    padding: 2rem 0;
  }

  html .trade-off-card {
    width: 70vw;
    padding: 4vw;
    margin-left: 0;
  }

  html .vs-circle {
    /* margin-left: 0; */
    margin: 4vw 0;
  }

  .manage-cadences .double-input input,
  .manage-cadences .double-input select {
    height: 8vh;
  }
}
