.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-mt-type-line {
  width: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
