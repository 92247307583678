.leadership-page {
  padding-bottom: 2rem;
}

.leadership-page table th,
.leadership-page table td {
  width: 16vw;
}

.leadership-page table input,
.leadership-page table select {
  width: 14vw;
  padding: 0.5vw 1vw;
  border-radius: 5px;
}

.leadership-page .leadership-step {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leadership-page h2 {
  text-align: center;
  margin: 3rem 0;
}

.leadership-page .hidden {
  display: none;
}

/* Buttons */

.leadership-page .next {
  right: 1.5rem;
}

.leadership-page .previous {
  left: 1.5rem;
}

.previous {
  transform: scaleX(-1);
}

/* Buttons */

button a {
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.nav-progress-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  display: flex;
  align-items: center;
}

.step {
  display: flex;
  align-items: center;
}

.progress-circle {
  width: 1.25vw;
  height: 1.25vw;
  border-radius: 50%;
}

.progress-rect {
  width: 8vw;
  height: 5px;
}

.progress {
  background-color: rgb(225, 225, 225);
}

.active-progress {
  background-color: rgb(9, 38, 213);
}

/* more */

.leadership-page .how-to-define-text-container {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leadership-page .text-section {
  width: 36vw;
  border-radius: 5px;
  padding: 2vw;
  margin: 1rem 0;
}

.leadership-page .define-text-title {
  font-size: 18px;
  margin-bottom: 1rem;
}

.leadership-page .how-to-define-text-container p {
  text-align: center;
}

.leadership-page .verb {
  color: rgb(237, 82, 46);
}

.leadership-page .descriptor {
  color: rgb(9, 38, 213);
}

.leadership-page .subject {
  color: rgb(246, 145, 60);
}

.leadership-page .objectives-apply-step table {
  margin-top: 2rem;
}

.leadership-page .objectives-apply-step th,
.leadership-page .objectives-apply-step td {
  width: 15vw;
}

/* intro */

.leadership-page .leadership-intro-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leadership-page .leadership-intro-step .intro-title {
  width: 65vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.leadership-page .intro-summary-container .intro-summary-point {
  border-radius: 5px;
  width: 18vw;
  padding: 1.5vw 1vw;
  height: 40vh;
}

html body .leadership-page .hidden {
  display: none;
}

/* intro */

.leadership-page .enterprise-strategy-step tr {
  align-items: flex-start;
  margin-bottom: 2rem;
}

.leadership-page .enterprise-strategy-step th,
.leadership-page .enterprise-strategy-step td {
  width: 16vw;
}

.leadership-page .button-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leadership-page .enterprise-strategy-step .big-btn {
  width: 15vw;
  padding: 1vw;
  border-radius: 2.5px;
  margin-bottom: 1rem;
}

.leadership-quiz .quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  border-radius: 5px;
}

.leadership-quiz .quiz-container .answers {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.leadership-quiz .quiz-container .answer {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
}

.leadership-quiz .quiz-container .answer-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid rgb(246, 145, 60);
  margin-right: 1rem;
}

.leadership-quiz .quiz-container .reminder {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  color: rgb(138, 138, 138);
}

.leadership-quiz .quiz-container .question {
  font-size: 18px;
}

.leadership-page .option-div-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.leadership-page .option-select-title {
  font-size: 18px;
}

.leadership-page .option-div {
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 25vw;
  cursor: pointer;
}

.leadership-page .option-div input {
  width: 25px;
  height: 25px;
  margin-right: 1rem;
}

.leadership-page .add-btn {
  width: 14vw;
  padding: 0.75rem 0;
  position: relative;
  top: 3vh;
}
