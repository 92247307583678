.meeting-view {
  padding-top: 5rem;
}

.meeting-view h2 {
  width: auto;
  text-align: center;
  margin: 2rem auto;
}

.meeting-view .meetingview-view-body {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
}

.meeting-view .meetingview-nav-container {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.meetingview-nav-container .nav-option {
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  border: 1px solid rgb(238, 238, 238);
  cursor: pointer;
}

.meetingview-nav-container .active {
  border-bottom: 2px solid rgb(9, 38, 213);
}

.blocked-card-container {
  display: flex;
  justify-content: flex-start;
  /* align-items: flex-start; */
  padding: 0.5rem 0.5rem 0.5rem 0.2rem;
  overflow-x: auto;
  border-radius: 5px;
  align-items: stretch;
}

.blocked-card {
  padding: 1vw 2vw;
  width: 20vw;
  min-width: 15rem;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deprioritized-card {
  width: 25vw;
}

.blocked-card {
  margin-right: 1vw;
}

.blocked-card select {
  margin-top: 0.5rem;
  border-radius: 2.5px;
  padding: 0.5vw 1vw;
  background-color: rgba(238, 238, 238, 0.7);
  border: none;
  max-width: none;
}

.blocked-card select option {
  width: 100vw;
}

.blocked-bullet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  margin-bottom: 2rem;
}

.blocked-bullet b {
  color: rgb(9, 38, 213);
  margin-bottom: 0.5rem;
}

.blocked-section h3 {
  font-size: 20px;
  margin: 0;
}

.trade-off-card {
  width: 16vw;
  padding: 2vw;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  transition: all 250ms;
  cursor: pointer;
}

.trade-off-bullet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  margin-bottom: 2rem;
}

.trade-off-bullet b {
  color: rgb(9, 38, 213);
  margin-bottom: 0.5rem;
}

.vs-circle {
  padding: 2rem;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  background-color: rgb(237, 82, 46);
  font-size: 24px;
  margin: 0 1rem;
}

.trade-offs h3 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 2rem;
}

.selected-trade-off {
  border: 1px solid rgb(9, 38, 213);
}

.selected-trade-on {
  border: 1px solid rgb(252, 74, 34);
}

.governance-view .btn-cell {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* MEETING VIEW MODAL STARTS */

.meeting-view-overlay,
.add-option-overlay {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 8;
}

.meeting-view-spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 10;
}
.meeting-view-spinner:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: transparent #fff transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* MEETING VIEW MODAL ENDS */

.f2w-assignment-component .f2w-card-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-x: auto;
  border-radius: 5px;
  padding: 2rem 0 2rem 1rem;
}
