.comment-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80%;
}

.comment-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.no-comment-box {
  display: flex;
  flex-direction: column;
  background-color: #c8c8c8;
  padding: 1rem;
  width: 100%;
}

.comment-meta {
  width: 100%;
  font-size: 14px;
  color: rgb(49, 44, 43);
  text-align: left;
}

.comments-table {
  display: grid;
  grid-template-columns: 0.1fr 0.8fr 0.8fr 1fr 0.6fr 0.6fr 0.2fr;
  gap: 0.2rem;
  border-radius: 8px;
  padding: 1rem 1rem;
}

.activity-actions-table {
  display: grid;
  grid-template-columns: 0.1fr 1.2fr 0.4fr 0.4fr 0.2fr;
  gap: 0.2rem;
  border-radius: 8px;
  padding: 1rem 1rem;
}

.comments-table-header {
  text-align: left;
  font-weight: bold;
  padding-left: 1rem;
}

.comments-table-cell {
  text-align: left;
  padding-left: 1rem;
  word-wrap: 'break-word';
}

.comments-modal {
  word-break: break-word;
  .modal-container {
    text-align: left;
    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
}

.action-comment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

.action-comment-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.action-comment-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.comment-bubble {
  background-color: #737f8f;
  color: white;
  border-radius: 8px;
  padding: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
  font-size: 14px;
}

.blocker_background {
  background-color: rgb(237, 82, 46);
}

.decision_background {
  background-color: rgb(9, 38, 213);
}

.update_background {
  background-color: rgb(127, 131, 155);
}

.admin_background {
  background-color: #9933ff;
}
