.custom-box-node {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  height: 120px;
  background-color: #f3ccac;
  border-radius: 0.625rem;
  //   box-shadow: 0 5px 15px #ccc;
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
  &.change-bg-color {
    background-color: #f2b7b761;
    .single-node {
      &:last-child {
        p {
          color: red;
          font-weight: bold;
          word-break: break-word;
        }
      }
    }
  }
  &.blur-box {
    opacity: 35%;
  }
  &.custom-box-node-assignee {
    height: 170px;
  }
  &.searched-border {
    background-color: white;
    border: 3px solid #f6ab6c;
    border-top: 0.7rem solid #f6ab6c;
    animation: fadeIn 2s;
  }
}

.light-red-box {
  background-color: rgb(237 82 46 / 4%);
  border: 1px solid rgba(237, 82, 46, 1);
  animation: fadeIn 2s;
}

.light-orange-box {
  background-color: rgba(246, 145, 60, 0.2);
  border: 1px solid rgba(246, 145, 60, 1);
}

.light-blue-box {
  background-color: rgba(9, 37, 214, 0.2);
  border: 1px solid rgba(9, 37, 214, 1);
}

.box-level-1 {
  background-color: white;
  border: 3px solid #737373;
  border-top: 0.7rem solid #737373;
  animation: fadeIn 2s;
}

.box-level-2 {
  background-color: white;
  border: 3px solid #0926d5;
  border-top: 0.7rem solid #0926d5;
  animation: fadeIn 2s;
}

.box-level-3 {
  background-color: white;
  border: 3px solid #5970f8;
  border-top: 0.7rem solid #5970f8;
  animation: fadeIn 2s;
}

.box-level-recommendation {
  background-color: white;
  border: 3px solid #13599b;
  border-top: 0.7rem solid #13599b;
  animation: fadeIn 2s;
}

.box-level-l3-recommendation {
  background-color: white;
  border: 3px solid #ed522e;
  border-top: 0.7rem solid #ed522e;
  animation: fadeIn 2s;
  opacity: 0.63;
}

// .light-grey-box {
//   background-color: white;
//   border: 3px solid #f6ab6c;
//   border-top: 0.7rem solid #f6ab6c;
//   animation: fadeIn 2s;
// }

.topic-box {
  border: 1.5px solid grey;
  animation: fadeIn 2s;
  background-color: white;
}

.topic-blocker {
  background-color: #f6ab6c;
  font-weight: bold;
  color: white;
  padding: 0.1rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  margin-bottom: 1rem;
}

.a {
  // visibility: hidden;
}

.b {
  // visibility: hidden;
}

.single-icon-zoom {
  width: 0.9vw;
  height: 1.8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(237, 82, 46, 0.3);
  border-radius: 100%;
  padding: 0.313rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .material-symbols-outlined.icon-size {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    color: white;
  }
}

.color-zoom-out {
  background-color: rgb(237 82 46);
}

.icons-list-node-box {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  gap: 0.313rem;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
  margin-bottom: 0.313rem;
  list-style-type: none;
}

.inner-node-text {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  p {
    color: black !important;
  }
}

.node-text-list {
  padding: 0.625rem;
  list-style: none;
  margin: 0;
}

.single-node {
  &:not(:last-child) {
    margin-bottom: 0.625rem;
  }
  h2 {
    color: rgb(9, 38, 213);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.25rem 0;
    text-align: initial;
  }
  p {
    color: black;
    text-align: initial;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    margin: 0;
    word-break: break-word;
    overflow: auto;
  }
}

.custom-box-node-team-flow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  // justify-content: center;
  width: 200px;
  height: 120px;
}

.team-flow-select {
  border: none;
  border-radius: 10px;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  font-size: 14px;
  width: 100%;
  height: 40px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url('../../../assets/dropdown-icon.svg') no-repeat center rgb(242, 242, 242);
  background-position: right 14px center;
  &:focus {
    outline: none;
    border: 1px solid rgb(9, 38, 213);
  }
  option {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.vc-btn {
  background-color: rgb(237, 82, 46);
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 14px;
  &.active {
    // background-image: linear-gradient(to left top, #f49782, #f4876d, #f37659, #f06544, #ed522e);
    background-image: linear-gradient(to left top, #f6913c, #f48336, #f27432, #f0642f, #ed522e);
  }
}

.vc-btn.active {
  background-image: linear-gradient(to left top, #f6913c, #f6913c, #f6913c, #f6913c, #f6913c);
}

.shared-box {
  height: 1.2rem;
  // width: 2.5rem;
  padding: 0 0.5rem 0 0.5rem;
  display: flex;
  color: white;
  border-radius: 6px;
  font-size: 0.75rem;
  background-color: rgb(175, 177, 180);
  width: fit-content;
}

.shared-box-empty {
  height: 1.2rem;
  width: 2.5rem;
  padding: 0 0.5rem 0 0.5rem;
  display: flex;
  color: white;
  border-radius: 6px;
  font-size: 0.75rem;
  background-color: none;
}

.owner {
  font-size: 0.75rem;
  margin-left: auto;
  margin-right: 0.2rem;
}

.goal-desc {
  height: 5rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.goal-desc::-webkit-scrollbar {
  width: 0.25rem;
}

.goal-desc::-webkit-scrollbar-thumb {
  background-color: #c5c1c1;
  border-radius: 1rem;
}
