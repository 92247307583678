.box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
}

.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-wrap: wrap;
  flex-direction: column;
}

.dashboard-row {
  display: flex;
  flex-direction: row;
  width: 80vw;
}

.widget-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 1rem;
  padding: 1rem 2rem;
}

.one-column {
  width: 100%;
}

.two-column {
  width: 50%;
}

.widget-title {
  font-size: 1.3rem;
  padding: 1rem 0rem 1rem;
}

.widget-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.widget-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.widget-grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.widget-grid-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
}

.widget-grid-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.widget-grid-title {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-grid-item {
  border-bottom: 1px solid rgb(238, 238, 238);
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-grid-item .edit-close-button {
  float: left;
}

.resolve-button {
  padding: 0.75rem;
  background-color: rgb(237, 82, 46);
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.objective .modal-dual-btn {
  flex-direction: column;
}

.objective .dual-btn {
  margin: 0 1rem;
  padding: 0.5rem 0;
  width: 10vw;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.objective .positive-btn {
  background-color: rgb(237, 82, 46);
  color: #fff;
}

.objective .negative-btn {
  background-color: rgb(200, 200, 200);
}

.duo-text {
  display: flex;
  flex-direction: column;
}
