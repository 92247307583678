.table-wrapper .external-member-alloc-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.external-member-alloc-table .widget-grid-title {
  font-size: 1rem;
  font-weight: normal;
  padding: 0.5rem 0;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
