.kpi-status-table {
  display: grid;
  grid-template-columns: 0.1fr 2fr 0.8fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.1fr;
  row-gap: 0.5rem;
  //column-gap:1rem; *removed so row can be highlighted in one color, implemented spacing with cell padding
  position: relative;
  text-align: left;
  row-gap: 1rem;
}

.kpi-status-table-summary-obj {
  display: grid;
  grid-template-columns: 0.1fr 2fr 1.5fr 0.7fr 0.7fr 1fr 0.7fr 0.7fr 1fr 1fr 1fr 0.1fr;
  //row-gap: 0.5rem;
  column-gap: 1rem; //*removed so row can be highlighted in one color, implemented spacing with cell padding
  position: relative;
  text-align: left;
  row-gap: 1rem;
}

.kpi-status-table-cell {
  padding: 0 0.5rem 0 0.5rem;
  word-break: break-word;
}

.kpi-status-table-cell-summary-obj {
  word-break: break-word;
}

.kpi-status-table-header {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
}

.kpi-status-table-header-summary-obj {
  font-weight: bold;
  text-align: left;
}

.kpi-status-table-l3 {
  display: grid;
  grid-template-columns: 1.3fr 0.6fr 0.6fr 0.6fr 0.6fr 0.6fr 1.3fr 0.6fr 0.6fr 1fr 1fr;
  row-gap: 0.5rem;
  //column-gap:1rem; *removed so row can be highlighted in one color, implemented spacing with cell padding
  position: relative;
  text-align: left;
}

.kpi-status-table-l3-no-doc {
  display: grid;
  grid-template-columns: 1.3fr 0.6fr 0.6fr 0.6fr 0.6fr 0.6fr 1.3fr 0.6fr 0.6fr 1fr;
  row-gap: 0.5rem;
  //column-gap:1rem; *removed so row can be highlighted in one color, implemented spacing with cell padding
  position: relative;
  text-align: left;
}

.kpi-status-table-l3-new {
  display: grid;
  grid-template-columns: 0.1fr 1.3fr 1fr 0.6fr 0.6fr 1.3fr 0.6fr 0.6fr 0.8fr 1fr 1fr 0.3fr;
  row-gap: 0.5rem;
  //column-gap:1rem; *removed so row can be highlighted in one color, implemented spacing with cell padding
  position: relative;
  text-align: left;
}

.kpi-status-table-l3-no-doc-new {
  display: grid;
  grid-template-columns: 0.1fr 1.3fr 0.6fr 0.6fr 0.6fr 0.8fr 0.6fr 1.3fr 0.6fr 0.6fr 0.8fr;
  row-gap: 0.5rem;
  //column-gap:1rem; *removed so row can be highlighted in one color, implemented spacing with cell padding
  position: relative;
  text-align: left;
}

.kpi-dashboard-configuration-table {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  row-gap: 0.5rem;
  //column-gap:1rem; *removed so row can be highlighted in one color, implemented spacing with cell padding
  position: relative;
  text-align: left;
  row-gap: 1rem;
  width: 60rem;
}

.select-kpis-container .action-buttons {
  display: flex;
  justify-content: flex-end;
}

.select-kpis-container {
  width: fill-available;
}
.select-kpis-table-header {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
}

.select-kpis-table-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
}

.grid-select-kpis-table-container {
  display: flex;
  flex-direction: column;
}

.grid-select-kpis-table-container {
  .grid-section {
    display: grid;
    grid-template-columns: 1fr 0.4fr 0.3fr 0.1fr;
    padding-bottom: 0.5rem;
    position: sticky;
    top: 0;
    background-color: white;
  }

  .grid-header {
    display: grid;
    grid-template-columns: 1fr 0.4fr 0.3fr 0.1fr;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;

    .grid-cell {
      padding: 12px;
      font-weight: bold;
      text-align: left;
    }
  }

  .grid-body {
    height: 64vh;
    overflow-y: scroll;
    overflow-x: hidden;
    .grid-row {
      display: grid;
      grid-template-columns: 1fr 0.4fr 0.3fr 0.1fr;
      border-bottom: 1px solid #e0e0e0;
    }
  }

  .grid-cell {
    padding: 12px;
    display: flex;
  }

  .objective-cell {
    display: flex;
    gap: 8px;
    width: 100%;

    .expand-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      min-width: 24px;
    }

    .expand-button-spacer {
      min-width: 24px;
    }

    .objective-content {
      flex: 1;
      .objective-statement {
        display: flex;
        justify-content: flex-start;
        word-break: break-word;
        text-align: left;
        min-height: 24px; /* Add this to ensure minimum height */
        position: relative; /* Add this for proper child positioning */
      }
      .color-bar {
        flex-shrink: 0; /* Prevent shrinking */
        min-height: inherit; /* Inherit parent's min-height */
        width: 10px;
        border-radius: 15px;
        margin-right: 0.5rem;
      }
    }
  }

  .kpi-cell {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    .spacer {
      min-width: 24px;
    }

    .kpi-content {
      flex: 1;
      .kpi-name {
        display: flex;
        justify-content: flex-start;
        word-break: break-word;
        text-align: left;
      }
    }
  }

  .kpi-row {
    background-color: #ffffff;

    &:hover {
      background-color: #f8f9fa;
    }
  }

  .checkbox-cell {
    justify-content: center;

    input[type='checkbox'] {
      margin: 0;
    }
  }
}
