.user-setting-modal-title {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 25px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  color: rgb(94, 134, 204);
  margin-bottom: 1rem;
}
