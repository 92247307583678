.strategy-table {
  width: 80vw;
  max-width: 1224px;
}

.strategy-table tr {
  align-items: flex-start;
  width: 100%;
}

.strategy-table td {
  width: 28%;
  padding: 1rem;
  word-break: break-word;
}
