.saved-searched-objectives-table {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 2fr 4fr 1.5fr;
  row-gap: 0.3rem;
  column-gap: 0.2rem;
}

.title-obj-map-border {
  border-bottom: 2px solid black;
  width: 320px;
  padding-bottom: 0.1rem;
}

.goal-text {
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid black;
  background-color: #e4e4e4;
  padding: 2px 0.5rem;
  font-size: 15px;
}
