.manage-cadences {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
}

.manage-cadences .cadence-form-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
}

.manage-cadences input,
.manage-cadences select {
  background-color: #fff;
  border: 1px solid rgb(238, 238, 238);
}

.manage-cadences .number-of-input {
  width: 4vw;
  margin-right: 0.5rem;
}

.manage-cadences .double-input {
  margin: 0.5rem auto;
}

.manage-cadences .double-input input,
.manage-cadences .double-input select {
  height: 4vh;
  padding: 0 0.5vw;
  border-radius: 5px;
}

.manage-cadences label {
  margin: 1rem auto;
}

.manage-cadences .form-field {
  margin-bottom: 1.5rem;
}

.manage-cadences .big-btn {
  margin: 1rem auto;
}
