.odin-email-inbox-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 0.8rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 100%;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 8px;
  /* word-wrap: break-word; */
  word-break: break-word;
}

.odin-mention-input-box {
  word-break: break-word;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}

.odin-mention-input-box-body_bottom_text {
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  background-color: rgb(242, 242, 242);
}

.odin-email-inbox-list-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
}

.odin-email-inbox-list-table-cell {
  padding: 0 0.5rem 0.5rem 0;
}

.odin-email-inbox-list-row {
  display: grid;
  grid-template-columns: 0.6fr 0.3fr 1fr 1fr 0.3fr;
  column-gap: 0.3rem;
}

.odin-email-detail-row {
  display: grid;
  grid-template-columns: 0.6fr 0.7fr 1fr 0.2fr;
  column-gap: 0.3rem;
}

.odin-email-detail-text {
  text-align: left;
  color: black;
}
