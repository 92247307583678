.area-objective-step-component .goal-container .delete-btn {
  /* margin: 0 1rem 1rem 0; */
  position: block;
}

.area-objective-step-component .form-field {
  margin-bottom: 1.5rem;
}

.area-objective-step-component .form-field label {
  margin-bottom: 0.75rem;
}

.area-objective-step-component .form-field:first-of-type {
  margin-top: 1rem;
}

.area-objective-step-component .form-field input,
.area-objective-step-component .form-field #verb,
.area-objective-step-component .form-field #descriptor_input {
  width: 16vw;
  padding: 0 1vw;
  height: 35px;
}

.area-objective-step-component .form-field select {
  width: 18vw;
}

.area-objective-step-component .goal-container {
  min-width: 40vw;
}
