@keyframes szh-menu-show {
  from {
    opacity: 0;
  }
}

@keyframes szh-menu-hide {
  to {
    opacity: 0;
  }
}

.obj-map-menu {
  z-index: 999;
  position: relative;
  .szh-menu {
    font-family: sans-serif;
    font-size: 0.925rem;
    user-select: none;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 8px;
    min-width: 10rem;
    background-color: rgba(255, 255, 255, 1);

    &--state-opening {
      animation: szh-menu-show 0.15s ease-out;
    }

    &--state-closing {
      animation: szh-menu-hide 0.2s ease-out forwards;
    }
    &__item {
      border-radius: 6px;
      padding: 0.1rem 0.5rem;
      &--hover {
        color: #fff;
        background-color: #59a2ff;
      }
      &--submenu {
        position: relative;
        &::after {
          position: absolute;
          width: 7px;
          right: 0.625rem;
        }
      }
    }
  }
}

.obj-map-tool-tip {
  position: absolute; /* Positioning relative to the parent (li) */
  z-index: 10;
}

/* Position below the button */
.obj-map-tool-tip-text {
  top: 100%; /* Below the button */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Centering */
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap; /* Prevent line breaks */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.2s; /* Smooth transition */
}

/* Show tooltip when hovering */
li:hover .obj-map-tool-tip-text {
  opacity: 1; /* Fade in */
}

/* Additional positioning options */
/* Position above the button */
.obj-map-tool-tip.top {
  bottom: 100%; /* Above the button */
  left: 50%;
  transform: translateX(-50%);
}

/* Position to the left of the button */
.obj-map-tool-tip.left {
  top: 50%;
  right: 100%; /* To the left */
  transform: translateY(-50%); /* Center vertically */
}

/* Position to the right of the button */
.obj-map-tool-tip.right {
  top: 50%;
  left: 100%; /* To the right */
  transform: translateY(-50%); /* Center vertically */
}

.action-decision-count-box {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(228, 225, 225);
  background-color: rgb(228, 225, 225);
  border-radius: 5px;
  padding: 0.1rem 0.5rem;
  font-size: 10px;
}
