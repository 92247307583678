.governance-view {
  padding-top: 5rem;
}

.governance-view h2 {
  width: 60vw;
  text-align: center;
  margin: 2rem auto;
  font-size: 32px;
  padding-bottom: 2rem;
}

.governance-view .governance-view-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.governance-view .governance-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.governance-nav-container .nav-option {
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  border: 1px solid rgb(238, 238, 238);
  cursor: pointer;
}

.governance-nav-container .nav-option:first-of-type {
  margin-left: 0;
}

.governance-nav-container .active {
  border-bottom: 2px solid rgb(9, 38, 213);
}
