.team-step .time-input {
  width: 3vw;
}

.team-step input,
.team-step select {
  border-radius: 2.5px;
  padding: 0.25rem 0.5rem;
  border: 1px solid rgb(238, 238, 238);
}

.table-wrapper {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 10px;
}

.team-step .add-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-step .edit-btn,
.team-step .delete-btn {
  width: 22px;
  height: 22px;
  margin-left: 1rem;
  cursor: pointer;
  position: relative;
  bottom: 0.5rem;
}

.team-step .form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-step .form-container .form-field {
  justify-content: flex-start;
  align-items: flex-start;
}
