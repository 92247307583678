.form-field.action-field {
  &--kanban {
    width: 100%;
    .custom-input,
    .custom-select {
      width: -webkit-fill-available;
    }
    .custom-select {
      border: none;
      border-radius: 10px;
      padding: 0.5rem;
      font-size: 14px;
      width: 100%;
      height: 40px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: url('../../assets/drop-down.svg') no-repeat center rgb(242, 242, 242);
      background-position: right 14px center;
      &:focus {
        outline: none;
        border: 1px solid rgb(237, 82, 46);
      }
    }
  }
  .css-b62m3t-container {
    width: 100%;
  }
  .css-13cymwt-control {
    background-color: rgb(242, 242, 242);
    border: none;
    border-radius: 10px;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
}

.row-kanban-inputs {
  display: flex;
  align-items: center;
  gap: 24px;
}

.kanban-modal {
  .modal-container {
    text-align: left;
    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
}

.input-error,
.input-error {
  border: red !important;
  border-color: red !important;
}
