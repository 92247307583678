.impact-measure-table-container-component {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 1rem;
  margin-bottom: 3rem;
  width: 100%;
}

.impact-measure-table-container-component table td input {
  width: 25px;
  height: 25px;
}
