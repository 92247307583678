.kpi-wizard-list_objectives-table {
  display: grid;
  grid-template-columns: 2fr 0.3fr 0.5fr;
  row-gap: 1rem;
  column-gap: 1rem;
}

.kpi-wizard-list_objectives-row {
  display: contents; /* Ensures rows and headers align in the same grid */
}

.kpi-wizard-radio-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.kpi-wizard-kpis-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.kpi-wizard-kpis-suggested-table {
  display: grid;
  grid-template-columns: 0.1fr 1.3fr 0.4fr 0.5fr 0.4fr 0.3fr 0.5fr 0.5fr 0.5fr 0.3fr;
  row-gap: 1rem;
  column-gap: 1rem;
}

.kpi-wizard-kpis-existing-table {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.1fr;
  row-gap: 1rem;
  column-gap: 1rem;
}

.kpi-wizard-kpis-row {
  display: contents; /* Ensures rows and headers align in the same grid */
}

.kpi-wizard-input-box {
  height: 1.35rem;
  border-radius: 5%;
  width: -webkit-fill-available;
}

.sleek-full-blue-inverted {
  background-color: #fff;
  color: #4472c4;
  border: 1px solid #4472c4;
}

.input-error input,
.input-error select,
.input-error textarea {
  border-color: #eb001b;
}
