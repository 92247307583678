.gantt-modal {
  .modal-container {
    text-align: left;
    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
}

.gantt-legend-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: 14px;
}

.gantt-legend-item {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.gantt-legend-item {
  align-items: center;
}

.flex {
  display: flex;
  flex-direction: column;
}

.gantt-triangle1 {
  width: 0;
  height: 0;
  border-left: 12.857px solid transparent;
  border-right: 12.857px solid transparent;
  border-bottom: 14.288px solid #f6a8a2;
}

.gantt-triangle2 {
  width: 0;
  height: 0;
  border-top: 14.288px solid #f6a8a2;
  border-left: 12.857px solid transparent;
  border-right: 12.857px solid transparent;
}

.gantt-triangle3 {
  width: 0;
  height: 0;
  border-top: 14.288px solid #5398ff;
  border-left: 12.857px solid transparent;
  border-right: 12.857px solid transparent;
}

.gantt-rectangle {
  width: 40px;
  height: 20px;
  background: #5398ff;
  border-radius: 4px;
}

.gantt-container {
  display: flex;
  flex-direction: column;
}

.gantt-header {
  display: flex;
  overflow-x: auto;
}

.gantt-timeline {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  white-space: nowrap;
}

.gantt-months,
.gantt-days {
  display: flex;
}

.gantt-month,
.gantt-day {
  display: inline-block;
  text-align: center;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.gantt-month {
  font-weight: bold;
  background-color: #f5f5f5;
}

.gantt-day {
  width: 30px;
  height: 20px;
}

.gantt-body {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.gantt-item {
  position: relative;
  height: 30px;
  line-height: 30px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.gantt-item .gantt-label {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.gantt-item .gantt-tooltip {
  position: absolute;
  top: -25px;
  left: 0;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: normal;
}

.gantt-item .gantt-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 10px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}
