.reminder-email-meeting-action-table {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 0.8fr 0.8fr 0.8fr 0.7fr 0.5fr 0.5fr;
  row-gap: 0.3rem;
  column-gap: 1rem;
}

.reminder-email-meeting-action-table-print {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 0.8fr 0.8fr 0.8fr 0.4fr;
  row-gap: 0.3rem;
  column-gap: 1rem;
}

.reminder-email-other-activities-table {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 0.8fr 0.8fr 0.8fr 0.7fr 0.5fr 0.5fr;
  row-gap: 0.3rem;
  column-gap: 1rem;
}

.reminder-email-other-activities-table-print {
  display: grid;
  grid-template-columns: 2fr 2fr 0.8fr 0.8fr 0.7fr 0.1fr;
  row-gap: 0.3rem;
  column-gap: 1rem;
}

.action-reminder-modal {
  word-break: break-word;
  .modal-overlay {
    .modal-container {
      .modal-container-padding {
        width: 92%;
      }
    }
  }
}

.actions-activities-table-cell {
  display: table;
  table-layout: fixed;
  width: 100%;
  word-break: break-word;
}

.actions-description-cell {
  font-size: 14px;
  font-weight: normal;
}

.actions-description-cell:hover {
  background-color: rgb(182, 218, 240, 0.3);
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .bgu-column {
    width: 13rem;
  }
}

@media (min-width: 1501px) and (max-width: 2000px) {
  .bgu-column {
    width: 16rem;
  }
}
