.obj-report-modal {
  .modal-container {
    text-align: left;
    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
  width: 100%;
  height: 100%;
}

.obj-report-modal-title {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 25px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  color: rgb(94, 134, 204);
}

.meeting-topic-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 100%;
  /* border: 1px solid rgb(0 0 0 / 20%); */
  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border-radius: 10px;
  /* word-wrap: break-word; */
  word-break: break-word;
}

.meeting-topic-table {
  display: grid;
  grid-template-columns: 2fr;
  gap: 0.1rem 0.4rem;
}

.obj-meeting-topic-inner-grid {
  display: grid;
  grid-template-columns: 7fr 2fr 2fr 3fr;
}

.my-meeting-topic-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  height: 1.5rem;
  padding-top: 0.5rem;
}

.my-meeting-topics-cell {
  text-align: left;
  margin-top: 1rem;
}

.meeting-topic-tagbox {
  height: 1.3rem;
  width: 4rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  display: flex;
  color: white;
  border-radius: 6px;
  margin-right: 0.5rem;
  justify-content: center;
}
