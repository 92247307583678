.kpi-report-nav-container {
  display: flex;
  justify-content: start;
  align-items: center;
  width: fit-content;
  /* margin-bottom: 0.5rem; */
}

.nav-option-kpi-report {
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  border: 1px solid rgb(238, 238, 238);
  cursor: pointer;
}

.nav-option-text-kpi-report {
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  /* border: 1px solid rgb(238, 238, 238); */
  cursor: pointer;
}

.kpi-report-nav-container .active {
  border-bottom: 2px solid rgb(9, 38, 213);
}

.shadow-kpi-report {
  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
}

.grid-kpi-report-new-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .grid-section-new-table,
  .grid-kpi-row {
    display: grid;
    gap: 0.3rem;
    grid-template-columns: 27% 9.93% 6.62% 6.62% 6.62% 6.62% 6.62% 6.62% 9% 6.62% 2%;
    width: 100%;
  }

  .grid-kpi-report-new-table-header {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    padding: 12px;
  }

  .grid-header {
    display: grid;
    grid-template-columns: 1fr 0.4fr 0.3fr 0.1fr;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;

    .grid-cell {
      padding: 12px;
      font-weight: bold;
    }
  }

  .grid-body {
    .grid-row {
      display: grid;
      grid-template-columns: 1fr 0.4fr 0.3fr;
      border-bottom: 1px solid #e0e0e0;
    }
  }

  .grid-cell {
    padding: 12px;
    display: flex;
  }

  .objective-cell {
    display: flex;
    gap: 8px;
    width: 100%;

    .expand-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      min-width: 24px;
    }

    .expand-button-spacer {
      min-width: 24px;
    }

    .objective-content {
      flex: 1;
      .objective-statement {
        display: flex;
        justify-content: flex-start;
        word-break: break-word;
        text-align: left;
        min-height: 24px; /* Add this to ensure minimum height */
        position: relative; /* Add this for proper child positioning */
      }
      .color-bar {
        flex-shrink: 0; /* Prevent shrinking */
        min-height: inherit; /* Inherit parent's min-height */
        width: 10px;
        border-radius: 15px;
        margin-right: 0.5rem;
      }
    }
  }

  .kpi-cell {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    .spacer {
      min-width: 24px;
    }

    .kpi-content {
      flex: 1;
      .kpi-name {
        display: flex;
        justify-content: flex-start;
        word-break: break-word;
        text-align: left;
      }
    }
  }

  .kpi-row {
    background-color: #ffffff;

    &:hover {
      background-color: #f8f9fa;
    }
  }

  .checkbox-cell {
    justify-content: center;

    input[type='checkbox'] {
      margin: 0;
    }
  }
}
