@media (min-width: 501px) and (max-width: 1200px) and (orientation: portrait) {
  html select {
    max-width: none;
  }

  /* html .navigation {
    width: 94vw;
    padding: 0 2vw;
    height: 18vw;
  } */

  html .logo-container {
    width: 16vw;
    height: 16vw;
  }

  html .navigation .logo {
    width: 16vw;
    height: calc(16vw * (5 / 6));
  }

  html .navigation .show-navigation-button {
    width: 40px;
    height: 40px;
    margin-left: 0.75rem;
  }

  html .navigation .avatar,
  html .navigation .avatar-container {
    width: 10vw;
    height: 10vw;
  }

  html .navigation-new .progress-circle {
    width: 2.5vw;
    height: 2.5vw;
  }

  html .navigation-new .progress-rect {
    width: 10vw;
    height: 5px;
  }

  html .intro-summary-container {
    width: 96vw;
  }

  html .intro-summary-container .intro-summary-point {
    width: 30vw;
    height: 30vh;
    padding: 2rem 0;
    font-size: 18px;
  }

  html .big-btn {
    width: 30vw;
    margin-bottom: 2rem;
  }

  html tr {
    width: 75vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .team-step table th,
  .team-step table td {
    width: 25vw;
  }

  html .team-step .time-input {
    width: 10vw;
    font-size: 12px;
  }

  html .add-btn,
  html .edit-cap-btn {
    width: 25vw;
    font-size: 14px;
  }

  /* html .dual-button-container {
    width: 100vw;
    padding-bottom: 2rem;
  } */

  html .dual-button-container .dual-btn {
    width: 20vw;
  }

  html .add-employee-modal .modal-tf {
    padding: 2rem 2vw;
  }

  html .modal-tf .form-field label {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  html .modal-tf .form-field input {
    width: 36vw;
    padding: 2vw;
    font-size: 14px;
  }

  html .modal-dual-btn {
    width: 100%;
  }

  html .modal-dual-btn .dual-btn {
    width: 18vw;
    margin: 0 0.75rem;
  }

  html .add-goal-modal .modal-tf {
    padding: 4vw 2vw;
  }

  html #descriptor_input,
  html #verb {
    width: 36vw;
    padding: 2vw;
  }

  html .modal-tf .bullet-container {
    width: 40vw;
  }

  html body .objectives-step .edit-btn {
    width: 15px;
    height: 15px;
  }

  html body .objectives-step .delete-btn {
    width: 15px;
    height: 15px;
  }

  .objectives-step .goal-container {
    width: 60vw;
    padding: 4vw;
  }

  html .objectives-step .goal-input {
    width: 50vw;
    font-size: 14px;
    padding: 0 2vw;
  }

  html .obj-intro-modal .modal-tf {
    padding: 4vw;
  }

  html .activites-step select {
    width: 20vw;
  }

  html .activites-step table {
    padding: 1vw;
  }

  html .activites-step th,
  html .activites-step td {
    width: 40vw;
  }

  html .activites-step select {
    width: 26vw;
  }

  html .meetingview-view-body {
    flex-direction: column-reverse;
  }

  /* html .meetingview-view-body .scorecard-main-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  } */

  html .meetingview-view-body .scorecard-container {
    width: 80vw;
    margin: 0 0 4vw 0;
  }

  html .meetingview-view-body .scorecard-container .scorecard-header-container {
    padding-right: 6vw;
  }

  /* html .meeting-view-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  html .meeting-view-container .meeting-view-row {
    width: 90vw;
  }

  html .meeting-view-row h3 {
    text-align: center;
  }

  html .blocked-card-container {
    flex-direction: column;
    overflow-x: hidden;
  }

  html .blocked-card {
    width: 60vw;
    padding: 4vw;
    margin: 0 0 4vw 0;
    height: auto;
    position: relative;
    right: 1.5vw;
  }

  html .meetingview-nav-container .nav-option {
    text-align: center;
  }

  /* html .trade-offs {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } */

  html .trade-off-container {
    flex-direction: column;
    width: 90vw;
    padding: 2rem 0;
  }

  html .trade-off-card {
    width: 70vw;
    padding: 4vw;
    margin-left: 0;
  }

  html .vs-circle {
    /* margin-left: 0; */
    margin: 4vw 0;
  }

  .manage-cadences .double-input input,
  .manage-cadences .double-input select {
    height: 3vh;
  }
}
