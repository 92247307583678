html,
body,
#root,
.add {
  width: 100%;
  height: 800px;
}

.add {
  font-family: sans-serif;
  text-align: center;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

.select-search {
  display: grid;
  grid-template-columns: 250px 150px;
  align-items: flex-start;
}

.custom-select-wrapper-team .validation-error {
  margin-top: 5px;
  display: inline-block;
  font-size: 14px;
}

.select-search .modal-dual-btn {
  margin-top: 5px;
}

.form-field.action-field .css-13cymwt-control {
  border-radius: 10px 0 0 10px;
}
